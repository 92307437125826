import React, { useState, useEffect } from 'react';
import {
  Button, Drawer, Box, TextField, FormControl, Typography, IconButton, Select, MenuItem, InputLabel, Checkbox, ListItemText, OutlinedInput, FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const ProgramAddingComponent = ({ isOpen, onClose, program, saveProgram, users = [], techniques = [] }) => {
    const [formData, setFormData] = useState({
      title: '',
      description: '',
      daysCount: 0,
      image: '',
      media: [],
      techniques: [],
      isPublic: false,
      owner: ''
    });
  
    useEffect(() => {
        console.log("Techniques Loaded:", techniques); // Affiche les techniques chargées pour le diagnostic
        if (program) {
          setFormData({
            ...program,
            media: program.media || [],
            techniques: program.techniques.map(t => t._id) || [], // Assurez-vous que nous travaillons avec des ID
            owner: program.owner || '',
            isPublic: program.isPublic || false
          });
        } else {
          // Reset form when creating a new program
          setFormData({
            title: '',
            description: '',
            daysCount: 0,
            image: '',
            media: [],
            techniques: [],
            isPublic: false,
            owner: ''
          });
        }
      }, [program, techniques]); // Ajout de techniques aux dépendances pour recharger si elles changent
      

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };


  
  const handleMediaChange = (index, field, value) => {
    const updatedMedia = [...formData.media];
    updatedMedia[index] = { ...updatedMedia[index], [field]: value };
    setFormData({ ...formData, media: updatedMedia });
  };

  const handleAddMedia = () => {
    const newMedia = {
      url: '',
      order: formData.media.length + 1
    };
    setFormData({ ...formData, media: [...formData.media, newMedia] });
  };

  const handleDeleteMedia = (index) => {
    const filteredMedia = formData.media.filter((_, i) => i !== index);
    setFormData({ ...formData, media: filteredMedia });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.title || !formData.description) {
      alert('Title and description are required.');
      return;
    }
    
    const preparedData = {
      ...formData,
      daysCount: Number(formData.daysCount),  // Convert daysCount to a number
      media: formData.media.map(m => m._id),  // Assumption: Media IDs are stored
    };
  
    // Check if owner should be included
    if (!formData.isPublic && !formData.owner) {
      alert('Owner is required for non-public programs.');
      return;
    }
  
    console.log("Data being sent to save:", preparedData);
    saveProgram(preparedData);
    onClose();
  };
  
  

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
      <Box p={2}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Titre"
            name="title"
            value={formData.title || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={formData.description || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nombre de jours"
            name="daysCount"
            type="number"
            value={formData.daysCount || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="URL de l'Image"
            name="image"
            value={formData.image || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" sx={{ mt: 2 }}>Médias du programme</Typography>
          {formData.media.map((m, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
              <TextField
                label="URL du Média"
                value={m.url}
                onChange={(e) => handleMediaChange(index, 'url', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Ordre"
                type="number"
                value={m.order}
                onChange={(e) => handleMediaChange(index, 'order', parseInt(e.target.value, 10))}
                margin="normal"
              />
              <IconButton onClick={() => handleDeleteMedia(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={handleAddMedia} startIcon={<AddIcon />}>Ajouter Média</Button>
            <FormControl fullWidth margin="normal">
            <InputLabel id="technique-select-label">Techniques</InputLabel>
            <Select
                labelId="technique-select-label"
                id="technique-select"
                multiple
                value={formData.techniques}
                onChange={(event) => {
                const { value } = event.target;
                setFormData({ ...formData, techniques: typeof value === 'string' ? value.split(',') : value });
                }}
                input={<OutlinedInput label="Techniques" />}
                renderValue={(selected) => selected.map(id => techniques.find(technique => technique._id === id)?.title || '').join(', ')}
            >
                {techniques.map((technique) => (
                <MenuItem key={technique._id} value={technique._id}>
                    <Checkbox checked={formData.techniques.includes(technique._id)} />
                    <ListItemText primary={technique.title} />
                </MenuItem>
                ))}
            </Select>
            </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="owner-select-label">Propriétaire</InputLabel>
            <Select
              labelId="owner-select-label"
              id="owner-select"
              value={formData.owner || ''}
              onChange={(event) => setFormData({ ...formData, owner: event.target.value })}
            >
              {users.map((user) => (
                <MenuItem key={user._id} value={user._id}>
                  {user.firstName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                name="isPublic"
                checked={formData.isPublic}
                onChange={(e) => handleChange(e)}
              />
            }
            label="Programme public"
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              Sauvegarder
            </Button>
            <Button onClick={onClose} variant="text" sx={{ ml: 2 }}>
              Annuler
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default ProgramAddingComponent;
