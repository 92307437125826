import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      main: '#000000',
      contrastText: '#fff6e8',
    },
    secondary: {
      main: '#E1F6FF',
    },
    grey: {
      main: '#EEE',
    },
    error: {
      main: '#ff0000',
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#000',
      secondary: 'rgba(0, 0, 0, .7)',
    },
  },
  typography: {
    fontFamily: 'Manrope, sans-serif',
    body: {
      fontFamily: 'Manrope, sans-serif',
      lineHeight: '1.6em',
      fontWeight: '500',
    },
    body1: {
      fontFamily: 'Manrope, sans-serif',
      lineHeight: '1.6em',
      fontWeight: '500',
    },
    body2: {
      fontFamily: 'Manrope, sans-serif',
      lineHeight: '1.6em',
      fontWeight: '500',
    },
    body3: {
      fontFamily: 'Manrope, sans-serif',
      lineHeight: '1.6em',
      fontWeight: '500',
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Taille par défaut du label pour correspondre au bouton
          fontSize: '0.875rem',
          lineHeight: '1', 
        },
        outlined: {
          // Style pour l'état réduit du label
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          // Styles pour le texte à l'intérieur de l'input
          fontSize: '0.875rem', // Assurez-vous que cela correspond à la taille du bouton
          padding: '12px 14px', // Ajustez selon vos besoins pour le padding
          lineHeight: '1.5', // La hauteur de ligne pour aligner verticalement le texte
          // Supprimez les valeurs de hauteur si elles causent des problèmes de mise en page
        },
        notchedOutline: {
          // Styles pour l'outline
          borderColor: 'rgba(0, 0, 0, 0.23)',
          borderRadius: '6px', // Arrondi maximum
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // Désactiver l'élévation pour tous les boutons supprime l'ombre
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          width: '100%', // Boutons en pleine largeur
          borderRadius: '8px', // Arrondi maximum
          boxShadow: 'none', // Pas d'ombre
          textTransform: 'none', // Pas de texte en majuscule
          fontWeight: '700 !important', // Semi-bold
          padding: '12px 16px 9px !important',
          fontSize: '0.875rem',
        },
      },
    },
    
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: 'Hybi11 Amigo',
          fontWeight: '600',
        },
        h2: {
          fontFamily: 'Hybi11 Amigo',
          fontWeight: '600',
        },
        h3: {
          fontFamily: 'Hybi11 Amigo',
          fontWeight: '600',
        },
        h4: {
          fontFamily: 'Hybi11 Amigo',
          fontWeight: '600',
        },
        h5: {
          fontFamily: 'Hybi11 Amigo',
          fontWeight: '600',
        },
        h6: {
          fontFamily: 'Hybi11 Amigo',
          fontWeight: '600',
        },
      },
    },
  },
});

export default theme;
