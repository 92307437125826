import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ZenSpinner from '../components/ZenSpinner';

const GuestRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return <ZenSpinner />;
  }

  if (currentUser) {
    return <Navigate to="/today" />;
  }

  return children;
};

export default GuestRoute;
