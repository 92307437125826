import React, { useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MeditationCardComponent from './MeditationCardComponent';

const MeditationList = () => {
  const [meditations, setMeditations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeditations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/meditations`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMeditations(data);
        } else {
          console.error('Erreur lors de la récupération des méditations.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des méditations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMeditations();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/meditation/${id}`);
  };

  if (loading) {
    // Afficher des Skeletons pendant le chargement
    return (
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
        {[1, 2, 3, 4].map((item) => (
          <Box key={item} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Skeleton variant="rectangular" height={140} />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
      {meditations.map((meditation) => (
        <MeditationCardComponent
          key={meditation._id}
          image={meditation.image || '/img/blue-background.svg'} // Fond bleu par défaut si pas d'image
          title={meditation.title}
          category={meditation.category}
          duration={meditation.duration}
          description={meditation.description}
          onClick={() => handleCardClick(meditation._id)}
        />
      ))}
    </Box>
  );
};

export default MeditationList;
