import React, { useState, useEffect } from 'react';
import {
  Button, Card, CardContent, IconButton, Typography, Grid, Box, Tabs, Tab
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ProgramAddingComponent from './ProgramAddingComponent';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ProgramManagementComponent = () => {
  const [programs, setPrograms] = useState([]);
  const [techniques, setTechniques] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0); // 0 pour public, 1 pour utilisateur
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentProgramToEdit, setCurrentProgramToEdit] = useState(null);

  useEffect(() => {
    fetchPrograms();
    fetchTechniques();
    fetchUsers();
  }, [selectedTab]);

  const fetchPrograms = async () => {
    const token = localStorage.getItem('token');
    const url = selectedTab === 0 ? `${backendUrl}/api/programs/public` : `${backendUrl}/api/programs/private`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const programsData = await response.json();
      setPrograms(programsData);
    } else {
      console.error('Erreur lors de la récupération des programmes');
    }
  };

  const fetchTechniques = async () => {
    const response = await fetch(`${backendUrl}/api/techniques`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setTechniques(data);
    } else {
      console.error('Erreur lors de la récupération des techniques');
    }
  };
  
  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${backendUrl}/api/users`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const usersData = await response.json();
      setUsers(usersData);
    } else {
      console.error('Erreur lors de la récupération des utilisateurs');
    }
  };

  const handleSaveProgram = async (formData) => {
    if (!formData.title || !formData.description || formData.daysCount <= 0) {
      alert("Veuillez remplir tous les champs obligatoires (titre, description, nombre de jours).");
      return;
    }
  
    const token = localStorage.getItem('token');
    let url = `${backendUrl}/api/programs`;
    let method = 'POST';
    if (formData._id) {
      url += `/${formData._id}`;
      method = 'PUT';
    }
  
    console.log("Sending data:", JSON.stringify(formData));
    const response = await fetch(url, {
      method,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
  
    if (response.ok) {
      console.log("Program saved successfully.");
      fetchPrograms();
      setIsDrawerOpen(false);
    } else {
      const errorResponse = await response.text();
      console.error('Failed to save the program:', errorResponse);
      alert('Échec de la sauvegarde du programme. Veuillez réessayer.');
    }
  };  

  const handleEdit = (program) => {
    setCurrentProgramToEdit(program);
    setIsDrawerOpen(true);
  };

  const handleDelete = async (programId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${backendUrl}/api/programs/${programId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.ok) {
      setPrograms(programs.filter(program => program._id !== programId));
    } else {
      console.error('Erreur lors de la suppression du programme');
    }
  };

  const handleAddProgram = () => {
    setCurrentProgramToEdit(null);
    setIsDrawerOpen(true);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="Publics" />
          <Tab label="Privés" />
        </Tabs>
      </Box>
      <Box sx={{ margin: 2 }}>
        <Button startIcon={<AddIcon />} onClick={handleAddProgram} variant="contained">
          Ajouter un programme
        </Button>
      </Box>
      <Box sx={{ ml: 2, mr: 2, mb: 4 }}>
        <Grid container spacing={2}>
          {programs.map(program => (
            <Grid item xs={12} sm={6} md={4} key={program._id}>
              <Card elevation={0} sx={{ pl: 1, pt: 1, borderRadius: '8px', border: '1px solid', borderColor: 'grey.300', overflow: 'hidden' }}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {program.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {program.description}
                  </Typography>
                  <Box>
                    <IconButton onClick={() => handleEdit(program)} size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(program._id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {isDrawerOpen && (
        <ProgramAddingComponent
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        program={currentProgramToEdit}
        saveProgram={handleSaveProgram}  // Assurez-vous que cette fonction est définie
        users={users}
        techniques={techniques}
      />
      )}
    </>
  );
};

export default ProgramManagementComponent;
