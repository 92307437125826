import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link, Button } from '@mui/material';
import LogoImage from '../assets/logo.svg'; // Assurez-vous que le chemin est correct

const WaitingPage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/login');
  };

  return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        p: 4,
      }}>
        <img src={LogoImage} alt="Logo" style={{ height: '50px', margin: '0 auto', marginBottom: '2rem' }} />
        <Typography variant="h6" sx={{ mb: 1 }}>Un lien de validation vient de vous être envoyé par email.</Typography>
        <Button variant="contained" color="primary" onClick={handleBackClick} sx={{ mt: 0.5 }}>
            Connexion
        </Button>
        <Box mt={2}>
          <Typography variant="body2">
            Un problème ?{' '}
            <Link component={RouterLink} to="/contact">Contactez-nous</Link>
          </Typography>
        </Box>
      </Box>
  );
};

export default WaitingPage;
