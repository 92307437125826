import React from 'react';
import { Box, Typography } from '@mui/material';
import { Share } from '@capacitor/share';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

const Invite = () => {
  const handleInvite = async () => {
    await Share.share({
      title: 'Découvrez Raoul et réduisez votre stress !',
      text: 'Salut, je voudrais te recommander cette application incroyable appelée Raoul. Utilise le code "COOL-RAOUL" pour obtenir 30 jours gratuits et découvrir les bienfaits de la méditation. Voici le lien pour la découvrir : heyraoul.fr',
      url: 'https://heyraoul.fr',
      dialogTitle: 'Inviter des amis'
    });
  };

  return (
    <Box onClick={handleInvite} sx={{ bgcolor:'secondary.main', py: 3, px: 3, borderRadius: '8px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="h6" sx={{ mb: 0, lineHeight:'1em', mb:0.8 }}>Parrainer des amis</Typography>
          <Typography variant="body2" sx={{ mb: 0, lineHeight:'1em' }}>Envoyer un accès gratuit</Typography>
        </Box>
          <ShareRoundedIcon sx={{ fontSize: 30 }} />
      </Box>
    </Box>
  );
};

export default Invite;
