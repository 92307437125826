import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton, Button, CardContent, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

import { useTheme } from '@mui/material/styles';
import getCategoryIcon from '../utils/getCategoryIcon'; 
import TechniqueListComponent from '../components/TechniqueListComponent';
import FixedHeightContainer from '../components/FixedHeightContainer';
import MeditationCardComponent from '../components/MeditationCardComponent';
import ZenSpinner from '../components/ZenSpinner';

const ProgramPage = ({ image, title, category, duration, description }) => {
  const { programId } = useParams(); // Obtient l'ID du programme depuis l'URL
  const [programData, setProgramData] = useState(null); // Initialiser l'état pour stocker les données du programme
  const [techniques, setTechniques] = useState([]); // État pour stocker les techniques
  const [completedSessions, setCompletedSessions] = useState([1, 2]); // Exemple d'état pour les sessions terminées
  const [currentSession, setCurrentSession] = useState(3); // Exemple d'état pour la session en cours
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchProgramDetails = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/programs/public/${programId}`, { // Note l'utilisation de programId pour cibler un programme spécifique
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('La récupération des détails du programme a échoué');
        }
        const data = await response.json();
        setProgramData(data); // Met à jour l'état avec les détails du programme récupérés
        console.log(data); // Affiche les données chargées pour vérification
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchProgramDetails();
  }, [programId]); // Dépendance à programId pour recharger si l'ID change

  useEffect(() => {
    // Supposons que programData est déjà chargé à ce stade
    if (programData && programData.techniques) {
      const fetchTechniquesDetails = async () => {
        try {
          const techniquesDetails = await Promise.all(programData.techniques.map(async (techniqueId) => {
            const response = await fetch(`${backendUrl}/api/techniques/${techniqueId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (!response.ok) {
              throw new Error('Échec du chargement des détails de la technique');
            }
            const data = await response.json();
            return { id: techniqueId, ...data }; // Incluez 'id' dans l'objet retourné
          }));
      
          setTechniques(techniquesDetails);
        } catch (error) {
          console.error("Erreur lors du chargement des détails des techniques :", error);
        }
      };
  
      fetchTechniquesDetails();
    }
  }, [programData]); // Dépend de programData pour s'exécuter
  
  const handleCardClick = (id) => {
    navigate(`/technique/${id}`); // Suppose que tu as une route pour les détails des techniques
  };

  const handleBack = () => {
    navigate(-1); // Navigue à la page précédente
  };

  const handleSessionStart = (sessionId) => {
    console.log(`Session ${sessionId} démarrée`);
    // Logique pour démarrer une session ici
  };

  const getSessionStyle = (sessionId) => {
    if (completedSessions.includes(sessionId)) {
      return { bgcolor: theme.palette.primary.main, color: 'white' }; // Complété
    } else if (sessionId === currentSession) {
      return { bgcolor: 'black', color: 'white' }; // En cours
    } else {
      return { bgcolor: 'white', color: theme.palette.grey[300], border: `2px solid ${theme.palette.grey[300]}` }; // A venir
    }
  };

  if (!programData) {
    // Si programData est null, on considère que les données sont encore en train de charger
    return <ZenSpinner />; // Affiche le spinner sur toute la page
  }
  
  return (
    <div>

      <Box sx={{pl:2, pr:2, pt:0.5}}>
      {image ? (
        <img src={image} alt={title} style={{ borderRadius: '4px', mt: 2, width: '100%', flexShrink: 0 }} />
      ) : (
        <Box sx={{ borderRadius: '4px', mt: 1.5, width: '100%', height: 200, bgcolor: theme.palette.primary.main, flexShrink: 0 }} />
      )}
      </Box>

        <IconButton
        onClick={handleBack}
        sx={{
            position: 'absolute',
            top: 30,
            left: 30,
            zIndex: 1,
            backgroundColor: 'white', // Couleur de fond de la bulle
            border: '1px solid #eee', // Bordure de la bulle
            borderRadius: '50%', // Rend la bulle parfaitement ronde
            padding: '8px', // Ajuste selon les besoins pour l'espace autour de l'icône
            '&:hover': {
            backgroundColor: 'white', // Maintient le fond blanc même au survol
            opacity: 0.7, // Optionnel, pour un effet au survol
            },
        }}
        >
        <ArrowBackIcon />
        </IconButton>

      <Box sx={{ flexGrow: 1, overflow: 'auto', pt:0.5, pl:3, pr:3 }}>
        <CardContent sx={{ p: 0 }}>
                <Typography gutterBottom variant="h5" sx={{ mt: 2.5, mb: 0.6 }}>
                  {programData ? programData.title : 'Chargement...'}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
                    <CalendarTodayIcon sx={{ fontSize: 14 }} />
                  </Box>
                  <Box sx={{ paddingTop: '2px' }}>{programData ? `${programData.daysCount} jours` : 'Chargement...'}</Box>
                </Typography>
                <Typography variant="body3" color="textSecondary" sx={{ mt: 1 }}>
                {programData ? programData.description : 'Chargement...'}
                </Typography>
            </CardContent>

      <Grid container spacing={0} justifyContent="center" sx={{ mt: 3, width: '100%', mx: 'auto', pt:3, pb:1, borderTop: '2px solid', borderBottom: '2px solid', borderColor: theme.palette.grey[300] }}>
        {[...Array(8).keys()].map((session) => (
          <Grid item xs={3} key={session} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              sx={{ 
                ...getSessionStyle(session + 1),
                '&:hover': { bgcolor: getSessionStyle(session + 1).bgcolor },
                borderRadius: '50%',
                width: 36, height: 36, // Réduit la taille des icônes
              }}
              onClick={() => handleSessionStart(session + 1)}
            >
              {completedSessions.includes(session + 1) ? <CheckIcon /> : <PlayArrowIcon />}
            </IconButton>
            <Typography variant="caption" sx={{ mt: 0.5, mb:1 }}>{session + 1}</Typography>
          </Grid>
        ))}
      </Grid>

      {/* Techniques utilisées */}
      <Box sx={{ mt: 4, mb:4 }}>
        <Typography variant="body" sx={{ mb:'0.5em', display:'block' }}>Techniques utilisées</Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
          {techniques.map((technique) => (
            <MeditationCardComponent
              key={technique.id}
              image={technique.image} // Assure-toi que tes données de technique ont un champ `image`
              title={technique.title}
              description={technique.description}
              onClick={() => handleCardClick(technique.id)} // 'technique.id' doit être l'ID de la technique
              showIcon={false}
            />
          ))}
        </Box>
      </Box>

      </Box>

    </div>
  );
};

export default ProgramPage;