import React, { useEffect } from 'react';

const images = [
  '/avatar/001.jpg',
  '/avatar/002.jpg',
  '/avatar/003.jpg',
  '/avatar/004.jpg',
  '/avatar/005.jpg',
  '/avatar/006.jpg',
];

const PreloadImages = () => {
  useEffect(() => {
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  return null;
};

export default PreloadImages;
