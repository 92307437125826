import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, AppBar, Toolbar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import FixedHeightContainer from '../components/FixedHeightContainer';
import LogoImage from '../assets/logo.svg';

// Import Lottie
import Lottie from 'react-lottie-player';
import cloudsAnimation from '../assets/clouds.json';

// Styles
const CloudsContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  zIndex: -1, // Assure que les nuages sont en arrière-plan
  pointerEvents: 'none', // Pour s'assurer que les boutons restent cliquables
});

const HomePageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  padding: theme.spacing(5),
}));

const Logo = styled('img')({
  width: '180px',
  margin: '1rem 0',
});

const HomePage = () => {
  const [playAnimation, setPlayAnimation] = useState(true);
  const lottieRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.goToAndPlay(5 * 1000, true); 
    }
  }, []);

  return (
    <FixedHeightContainer>
      <AppBar position="fixed" sx={{ backgroundColor: 'transparent', boxShadow: 'none', px: 1, py:1, display: 'flex'}}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <img src={LogoImage} alt="Logo" style={{ height: 40, marginTop:'-5px' }} />
          </Box>
        </Toolbar>
      </AppBar>
      <CloudsContainer>
        <Lottie
          ref={lottieRef}
          loop
          animationData={cloudsAnimation}
          play={playAnimation}
          speed={0.1}
          style={{ width: '100%', height: '100%' }}
        />
      </CloudsContainer>
      <Box sx={{ px:3, pt:10 }}>
        <Typography variant="h3" align="center">
          Médite avec tes amis
        </Typography>
        <Button variant="contained" color="primary" href="/signup" sx={{ mt: 2 }}>
          Commencer
        </Button>
        <Button variant="text" color="primary" href="/login" sx={{ mt: 0.5 }}>
          Connexion
        </Button>
      </Box>
    </FixedHeightContainer>
  );
};

export default HomePage;
