import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Grid, Button, Drawer, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../contexts/AuthContext';
import ZenSpinner from '../../components/ZenSpinner';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Badges = () => {
  const { currentUser } = useAuth();
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawer state
  const [selectedBadge, setSelectedBadge] = useState(null); // Badge sélectionné
  const [dialogOpen, setDialogOpen] = useState(false); // Popin pour les détails d'un badge

  useEffect(() => {
    const fetchBadges = async () => {
      if (currentUser && currentUser.userId) {
        try {
          const response = await fetch(`${backendUrl}/api/users/${currentUser.userId}/badges`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assurez-vous d'envoyer le token
            },
          });
          if (!response.ok) {
            throw new Error('Erreur lors de la récupération des badges');
          }
          const data = await response.json();
          setBadges(data); // Stocke les badges
          setLoading(false);
        } catch (error) {
          console.error('Erreur lors de la récupération des badges:', error);
          setLoading(false);
        }
      }
    };

    fetchBadges();
  }, [currentUser]);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleBadgeClick = (badge) => {
    setSelectedBadge(badge);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedBadge(null);
  };

  if (loading) {
    return <ZenSpinner />;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2.5 }}>
        <Typography variant="h6">Mes Badges</Typography>
        <IconButton onClick={handleOpenDrawer} sx={{ width:'fit-content', height:'34px' }} size="small">
            <MoreHorizRoundedIcon />
        </IconButton>
      </Box>
  
      {/* Afficher un message si aucun badge n'est débloqué */}
      {badges.length === 0 ? (
        <Typography variant="body2" color="textSecondary" textAlign="center">
          Tu n'as pas débloqué de badge
        </Typography>
      ) : (
        <Grid container>
          {badges.slice(0, 3).map((badge) => (
            <Grid item xs={4} sm={4} key={badge._id}>
              <Box textAlign="center" onClick={() => handleBadgeClick(badge)} sx={{ cursor: 'pointer'}}>
                <Avatar src={badge.imageUrl} alt={badge.title} sx={{ width: 68, height: 68, margin:'0 auto' }} />
                <Typography variant="body2" sx={{ fontWeight: 'bold', mt:1, lineHeight:'1.3em', px:0.5 }}>
                  {badge.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
  
      {/* Drawer for all badges */}
      <Drawer anchor="bottom" open={drawerOpen} onClose={handleCloseDrawer}>
        <Box sx={{ px: 3, pt: 2, pb: 5 }}>
          <IconButton onClick={handleCloseDrawer} sx={{ position: 'absolute', top: 8, left: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ mb: 3, textAlign: 'center' }}>Tous les badges</Typography>
          <Grid container spacing={2}>
            {badges.map((badge) => (
              <Grid item xs={6} sm={4} key={badge._id}>
                <Box textAlign="center" onClick={() => handleBadgeClick(badge)} sx={{ cursor: 'pointer' }}>
                  <Avatar src={badge.imageUrl} alt={badge.title} sx={{ width: 80, height: 80, margin:'0 auto'  }} />
                  <Typography sx={{ mt:1 }} fontWeight='bold' variant="body2">{badge.title}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Drawer>
  
      {/* Dialog for badge details */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        {selectedBadge && (
          <DialogContent>
            <Box textAlign="center">
              <Avatar src={selectedBadge.imageUrl} alt={selectedBadge.title} sx={{ width: 100, height: 100, margin:'0 auto' }} />
              <Typography sx={{ mt:1 }} variant="h6">{selectedBadge.title}</Typography>
              <Typography variant="body2" color="textSecondary">
                {selectedBadge.description}
              </Typography>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
  
};

export default Badges;
