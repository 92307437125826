import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, AppBar, Toolbar, IconButton, Button, Grid, Card, CardContent } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import useDeviceInfo from '../../utils/useDeviceInfo';
import { useAuth } from '../../contexts/AuthContext';
import tiboImage from '../../assets/tibo.png';

const ProgramStart = () => {
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const { currentUser, refreshUser } = useAuth();

  useEffect(() => {
    refreshUser();
  }, []); // Exécuter une seule fois au montage

  const handleStartProgram = () => {
    navigate('/program-start');
  };

  const handleSubscription = () => {
    navigate('/checkout');
  };

  return (
    <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'auto' }}>
      <AppBar position="static" sx={{ backgroundColor: 'secondary.main', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #fff',
              background: '#fff',
              borderRadius: '50%',
              mr: 2,
            }}
            aria-label="retour"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: '100%', py: 2, backgroundColor: 'secondary.main', p: 0, display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
        <img src="/img/cat.svg" alt="Cat" style={{ height: '25vh', display: 'block' }} />
      </Box>
      <Box sx={{ px: 3, py: 3, textAlign: 'center' }}>
        <Typography variant="h5" sx={{ mb: 0.5 }}>Apprendre à méditer</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
          <AccessTimeIcon sx={{ mr: 1 }} />
          <Typography fontWeight="bold" variant="body1">30 jours</Typography>
        </Box>
        {currentUser && currentUser.subscriptionStatus === 'active' ? (
          <Button variant="contained" color="primary" sx={{ mb: 1.5 }} onClick={handleStartProgram}>
            Démarrer le programme
          </Button>
        ) : (
          <Button variant="contained" color="primary" sx={{ mb: 1.5 }} onClick={handleSubscription}>
            Voir l'abonnement
          </Button>
        )}
        <Typography variant="body2" sx={{ mb: 2 }}>
          Accessible uniquement grâce à l'abonnement
        </Typography>
        <Grid container spacing={2} sx={{ mb: 1, px:4 }}>
          <Grid item xs={6}>
            <Card sx={{ textAlign: 'center', py: 2, boxShadow: 'none', border:'1px solid #ddd', borderRadius:'6px' }}>
              <Typography variant="body2" color="textSecondary">VIDÉO</Typography>
              <Typography variant="body1">4 cours</Typography>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ textAlign: 'center', py: 2, boxShadow: 'none', border:'1px solid #ddd', borderRadius:'6px' }}>
              <Typography variant="body2" color="textSecondary">SÉANCE</Typography>
              <Typography variant="body1">14 audios</Typography>
            </Card>
          </Grid>
        </Grid>
        <Card sx={{ boxShadow: 'none', mt:3 }}>
          <CardContent sx={{ py:0 }}>
            <Typography variant="body2">
              Découvre les bases de la méditation, ses effets positifs sur ton cerveau, et comment intégrer cette pratique dans ta vie de tous les jours pour réduire le stress et améliorer ton bien-être.
            </Typography>
          </CardContent>
        </Card>
        <Box>
            <img src={tiboImage} alt="Thibaut Avatar" style={{ height: '60px', borderRadius: '50%', border:'1px solid #ddd' }} />
        </Box>
        <Typography fontWeight="bold" variant="body1">Thibaut</Typography>
      </Box>
    </Box>
  );
};

export default ProgramStart;
