import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, TextField, Button, Alert, Modal, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LogoImage from '../../assets/logo.svg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success'); // 'success' or 'error'
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>/\\[\]]/.test(password);
    const isValid = password.length >= minLength && hasUpperCase && hasNumber && hasSpecialChar;

    let helperText = 'Le mot de passe doit contenir :';
    if (!isValid) {
      if (password.length < minLength) helperText += ' au moins 8 caractères,';
      if (!hasUpperCase) helperText += ' une majuscule,';
      if (!hasNumber) helperText += ' un chiffre,';
      if (!hasSpecialChar) helperText += ' un caractère spécial,';
    } else {
      helperText = '';
    }

    setPasswordHelperText(helperText);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = searchParams.get('token');

    if (!validatePassword(password)) {
      setMessage('Le mot de passe ne respecte pas les critères requis.');
      setMessageType('error');
      return;
    }

    if (password !== confirmPassword) {
      setMessage('Les mots de passe ne correspondent pas.');
      setMessageType('error');
      return;
    }

    setLoading(true);
    fetch(`${backendUrl}/api/users/reset-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, password })
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          setMessage('Mot de passe réinitialisé avec succès.');
          setMessageType('success');
          setModalOpen(true);
        } else {
          setMessage('Échec de la réinitialisation du mot de passe.');
          setMessageType('error');
        }
      })
      .catch(error => {
        console.error('Erreur lors de la réinitialisation du mot de passe:', error);
        setLoading(false);
        setMessage('Une erreur s\'est produite lors de la réinitialisation du mot de passe.');
        setMessageType('error');
      });
  };

  const handleClose = () => {
    window.location.href = 'raoulapp://deep-link/login';  // Deep link redirection vers l'app
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      p: 4,
    }}>
      <img src={LogoImage} alt="Logo" style={{ height: '30px', margin: '0 auto', marginBottom: '2em' }} />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h6" sx={{ mb: 0 }}>Réinitialiser le mot de passe</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Nouveau mot de passe"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={passwordHelperText}
            />
            <TextField
              label="Confirmer le nouveau mot de passe"
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 1.5 }}>
              Réinitialiser le mot de passe
            </Button>
          </form>
          {message && (
            <Alert severity={messageType} sx={{ mt: 2 }}>
              {message}
            </Alert>
          )}
        </>
      )}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '85%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Vous pouvez maintenant vous connecter
          </Typography>
          <Typography id="modal-description" variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Redirection automatique dans 5 secondes.
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Ouvrir l'application
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ResetPasswordPage;
