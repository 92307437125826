import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Device } from '@capacitor/device';

const XP = ({ experiencePoints, meditationTime }) => {
  const [xp, setXp] = useState(0); // XP actuel pour l'animation

  // Simule un compteur d'XP qui augmente progressivement
  useEffect(() => {
    let interval = setInterval(() => {
      setXp(prevXp => {
        if (prevXp < experiencePoints) {
          return prevXp + 1;
        } else {
          clearInterval(interval);
          return prevXp;
        }
      });
    }, 50); // Vitesse du compteur

    return () => clearInterval(interval);
  }, [experiencePoints]);

  return (
    <Box sx={{ backgroundColor: 'secondary.main', height:'100vh' }}> 
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        height="90vh"
        sx={{ px: 3 }}
      >
        {/* Image (remplacez par l'URL réelle si nécessaire) */}
        <Box component="img" src="/img/cat01.svg" alt="Méditation" width="55vw" />

        <Typography variant="body1" textTransform="uppercase" color="primary" mb={1} mt={3}>Méditation terminée</Typography>
        <Typography variant="h3" color="primary" mb={1}>Bravo !</Typography>

        {/* Bloc de contenu avec XP et temps de méditation */}
        <Box display="flex" justifyContent="space-around" width="100%" mt={2}>
          <Box sx={{ borderRadius: 2, width: '100%', p: 2, bgcolor: 'white', mr: 2 }} textAlign="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <TrendingUpIcon fontSize='0.6em' sx={{ mr: 0.5 }} />
              <Typography textTransform="uppercase" variant="body2">GAIN</Typography>
            </Box>
            <Typography variant="h6" color="primary">+{xp} XP</Typography>
          </Box>
          <Box sx={{ borderRadius: 2, width: '100%', p: 2, bgcolor: 'white' }} textAlign="center">
            <Box display="flex" justifyContent="center" alignItems="center">
              <AccessTimeIcon fontSize='0.6em' sx={{ mr: 0.5 }} />
              <Typography textTransform="uppercase" variant="body2">Temps</Typography>
            </Box>
            <Typography variant="h6" color="primary">{meditationTime} min</Typography>
          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default XP;
