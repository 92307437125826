// src/components/AuthContainer.js
import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  padding: theme.spacing(2),
}));

const AuthContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default AuthContainer;
