import React from 'react';
import { Container, Box, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProgramListComponent from '../components/ProgramListComponent';
import { useNavigate } from 'react-router-dom';

const ProgramListPage = () => {
  const navigate = useNavigate();

  const handleProgramClick = (programId) => {
    navigate(`/program/${programId}`);
  };

  return (
    <Box>
      <Box onClick={() => navigate('/temple')} sx={{ position: 'fixed', top: 0, left: 0, right: 0, bgcolor: 'background.paper', zIndex: 1100, display: 'flex', alignItems: 'center', p: 1 }}>
        <IconButton aria-label="retour">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body" sx={{ ml: 0.5, pt: '3px' }}>
          Retour
        </Typography>
      </Box>
      <Container sx={{ p: 3 }}>
        <Box sx={{ mt: 1, pt: 4, pb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>Programmes</Typography>
          <ProgramListComponent onProgramClick={handleProgramClick} />
        </Box>
      </Container>
    </Box>
  );
};

export default ProgramListPage;
