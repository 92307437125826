// src/components/onboarding/FinalSlide.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ManageInvitations from '../../components/profile/ManageInvitations';

const FinalSlide = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/today');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center', padding: 3 }}>

      <Box component="img" src="/img/duo.svg" alt="Invitation Duo" width="60vw" />
      
      <Typography variant="h4" sx={{ mb: 1, mt:2, width:'70vw' }}>
        Inviter un ami pour former ton duo
      </Typography>
      <Box>
        <ManageInvitations/>
      </Box>
      <Button variant="text" color="primary" sx={{ width:'60%' }} onClick={handleStart}>
        Plus tard
      </Button>
    </Box>
  );
};

export default FinalSlide;
