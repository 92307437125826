import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Toolbar, Typography, Box, Button, IconButton } from '@mui/material';
import theme from './theme/theme';
import { AuthProvider } from './contexts/AuthContext';
import { SocketProvider } from './contexts/SocketContext'; 
import NavBarComponent from './components/NavBarComponent';
import PreloadImages from './utils/PreloadImages';
import { Device } from '@capacitor/device';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import CloseIcon from '@mui/icons-material/Close';
import { green } from '@mui/material/colors';
import { PushNotificationHandler } from './utils/PushNotificationService';
import { pushNotificationReceived } from './utils/PushNotificationService';  

// Configure RevenueCat
const configureRevenueCat = async () => {
  if (typeof Purchases !== 'undefined') {
    const apiKey = process.env.REACT_APP_REVENUECAT_ANDROID_API_KEY;
    await Purchases.setup(apiKey);
  } else {
    console.warn('Purchases n\'est pas défini, RevenueCat ne peut pas être configuré');
  }
};
configureRevenueCat();

// Routes
import GuestRoute from './routes/GuestRoute';
import UserRoute from './routes/UserRoute';
import SubscriberRoute from './routes/SubscriberRoute';
import AdminRoute from './routes/AdminRoute';

// All Pages
import RedirectPage from './views/RedirectPage';
import HomePage from './views/HomePage';
import LoginPage from './views/LoginPage';
import SignUpPage from './views/SignUpPage';
import VerifyAccountPage from './views/profile/VerifyAccountPage';
import ResetPasswordPage from './views/profile/ResetPasswordPage';
import WaitingPage from './views/WaitingPage';
import PrivacyPolicyPage from './views/PrivacyPolicyPage';
import DeleteAccountPage from './views/DeleteAccountPage';
import AdsDuoPage from './views/checkout/AdsDuoPage';
import CheckoutPage from './views/checkout/CheckoutPage';
import StatusPage from './views/checkout/StatusPage';
import SuccessPage from './views/checkout/SuccessPage';
import CancelPage from './views/checkout/CancelPage';
import TodayPage from './views/TodayPage';
import TemplePage from './views/TemplePage';
import SessionPage from './views/SessionPage';
import TalkWithMe from './views/profile/TalkWithMe';
import ProgramStart from './views/program/ProgramStart';
import ProgramListPage from './views/ProgramListPage';
import ProgramPage from './views/ProgramPage';
import TechniqueListPage from './views/TechniqueListPage';
import TechniquePage from './views/TechniquePage';
import MeditationListPage from './views/MeditationListPage';
import MeditationPage from './views/MeditationPage';
import TimerPage from './views/TimerPage';
import AdminUsers from './views/admin/AdminUsers';
import AdminPrograms from './views/admin/AdminPrograms';
import AdminMeditations from './views/admin/AdminMeditations';
import AdminTechniques from './views/admin/AdminTechniques';
import AdminQuotes from './views/admin/AdminQuotes';
import AdminNotifications from './views/admin/AdminNotifications';
import LivePage from './views/room/LivePage';
import RoomPage from './views/room/RoomPage';
import FeedPage from './views/FeedPage';
import PlaygroundPage from './views/PlaygroundSubscription';
import FirstLogin from './views/onboarding/FirstLogin';
import AcceptInvitationPage from './views/AcceptInvitationPage'

import { useAuth } from './contexts/AuthContext';


const AppRoutes = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const showNavBar = ['/today', '/feed', '/temple'].includes(location.pathname);

  return (
    <>
      <Routes location={location}>
        {/* Route de redirection */}
        <Route path="/redirect" element={<RedirectPage />} />

        {/* Publics Routes */}
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/delete" element={<DeleteAccountPage />} />
        <Route path="/accept-invitation/:token" element={<AcceptInvitationPage />} />

        {/* Guest Routes */}
        <Route path="/" element={<GuestRoute><HomePage /></GuestRoute>} />
        <Route path="/login" element={<GuestRoute><LoginPage /></GuestRoute>} />
        <Route path="/signup" element={<GuestRoute><SignUpPage /></GuestRoute>} />
        <Route path="/verify" element={<GuestRoute><VerifyAccountPage /></GuestRoute>} />
        <Route path="/reset-password" element={<GuestRoute><ResetPasswordPage /></GuestRoute>} />
        <Route path="/waiting" element={<GuestRoute><WaitingPage /></GuestRoute>} />

        {/* User Routes */}
        <Route path="/checkout" element={<UserRoute><AdsDuoPage /></UserRoute>} />
        <Route path="/subscribe" element={<UserRoute><CheckoutPage /></UserRoute>} />
        <Route path="/cancel" element={<UserRoute><CancelPage /></UserRoute>} />
        <Route path="/status" element={<UserRoute><StatusPage /></UserRoute>} />
        <Route path="/success" element={<UserRoute><SuccessPage /></UserRoute>} />

        <Route path="/first" element={<UserRoute><FirstLogin /></UserRoute>} />

        <Route path="/today" element={<UserRoute><TodayPage /></UserRoute>} />
        <Route path="/temple" element={<UserRoute><TemplePage /></UserRoute>} />

        <Route path="/session/:sessionId" element={<UserRoute><SessionPage /></UserRoute>} />

        <Route path="/program" element={<UserRoute><ProgramListPage /></UserRoute>} />
        <Route path="/program/start" element={<UserRoute><ProgramStart /></UserRoute>} />

        <Route path="/meditation" element={<UserRoute><MeditationListPage /></UserRoute>} />

        <Route path="/technique" element={<UserRoute><TechniqueListPage /></UserRoute>} />
        <Route path="/technique/:techniqueId" element={<UserRoute><TechniquePage /></UserRoute>} />

        <Route path="/timer" element={<UserRoute><TimerPage /></UserRoute>} />
        <Route path="/feed" element={<UserRoute><FeedPage /></UserRoute>} />

        {/* Subscriber Routes */}
        <Route path="/program/:programId" element={<SubscriberRoute><ProgramPage /></SubscriberRoute>} />
        <Route path="/meditation/:meditationId" element={<SubscriberRoute><MeditationPage /></SubscriberRoute>} />
        <Route path="/talk" element={<SubscriberRoute><TalkWithMe /></SubscriberRoute>} />

        {/* Room Routes */}
        <Route path="/room/:roomCode" element={<UserRoute><RoomPage /></UserRoute>} />
        <Route path="/room/:roomCode/live" element={<UserRoute><LivePage /></UserRoute>} />

        <Route path="/playground" element={<UserRoute><PlaygroundPage /></UserRoute>} />

        {/* Admin Routes */}
        <Route path="/admin/users" element={<AdminRoute><AdminUsers /></AdminRoute>} />
        <Route path="/admin/programs" element={<AdminRoute><AdminPrograms /></AdminRoute>} />
        <Route path="/admin/meditations" element={<AdminRoute><AdminMeditations /></AdminRoute>} />
        <Route path="/admin/techniques" element={<AdminRoute><AdminTechniques /></AdminRoute>} />
        <Route path="/admin/quotes" element={<AdminRoute><AdminQuotes /></AdminRoute>} />
        <Route path="/admin/notifications" element={<AdminRoute><AdminNotifications /></AdminRoute>} />
      </Routes>
      {showNavBar && <NavBarComponent />}
    </>
  );
};

function App() {
  const [platform, setPlatform] = useState('');
  const [showToolbar, setShowToolbar] = useState(true);

  useEffect(() => {
    const getPlatform = async () => {
      const info = await Device.getInfo();
      setPlatform(info.platform);
    };

    getPlatform();
  }, []);

  const isProduction = process.env.NODE_ENV === 'production';

  const handleCloseToolbar = () => {
    setShowToolbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
      <SocketProvider>
        <BrowserRouter>
          {platform === 'web' && isProduction && showToolbar && (
            <AppBar color="default" elevation={0} sx={{ backgroundColor: '#e1f6ff', zIndex: 1201, py: 2 }}>
              <Toolbar>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography variant="body1" color="textPrimary" align="center" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                    L'application est disponible sur :
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AppleIcon />}
                      href="https://apps.apple.com/app/id6504459195"
                      target="_blank"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      App Store
                    </Button>
                    <Button
                      variant="contained"
                      startIcon={<AndroidIcon />}
                      href="https://play.google.com/store/apps/details?id=app.heyraoul.fr"
                      target="_blank"
                      sx={{ backgroundColor: green[500], '&:hover': { backgroundColor: green[700] }, whiteSpace: 'nowrap' }}
                    >
                      Google Play
                    </Button>
                  </Box>
                </Box>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleCloseToolbar}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          )}
          <PreloadImages />
          <Box sx={{ pt: platform === 'web' && isProduction && showToolbar ? '64px' : '0px' }}>
            <AppRoutes />
          </Box>
          {/* Seulement afficher les notifications si la plateforme n'est pas "web" */}
          {platform !== 'web' && <PushNotificationHandler />}
          {pushNotificationReceived()} 
        </BrowserRouter>
      </SocketProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
