import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Skeleton } from '@mui/material';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import EmojiFlagsRoundedIcon from '@mui/icons-material/EmojiFlagsRounded';
import { useAuth } from '../../contexts/AuthContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Statistics = () => {
  const { currentUser } = useAuth();
  const [totalMeditationMinutes, setTotalMeditationMinutes] = useState(0);
  const [averageMeditationMinutes, setAverageMeditationMinutes] = useState(0);
  const [completedSessions, setCompletedSessions] = useState(0);
  const [consecutiveDays, setConsecutiveDays] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserSessions = async () => {
      if (currentUser && currentUser.userId) {
        try {
          const response = await fetch(`${backendUrl}/api/meditation-sessions/user/${currentUser.userId}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch sessions');
          }
          const sessions = await response.json();
          const totalMinutes = sessions.reduce((acc, session) => acc + (session.listenedDuration || 0), 0);
          const averageMinutes = totalMinutes / sessions.length || 0;
          
          // Calculate consecutive days of meditation
          const dates = sessions.map(session => new Date(session.listenedOn).toDateString());
          const uniqueDates = [...new Set(dates)];
          let maxConsecutiveDays = 1;
          let currentStreak = 1;

          for (let i = 1; i < uniqueDates.length; i++) {
            const prevDate = new Date(uniqueDates[i - 1]);
            const currDate = new Date(uniqueDates[i]);
            const diffTime = Math.abs(currDate - prevDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            
            if (diffDays === 1) {
              currentStreak++;
              if (currentStreak > maxConsecutiveDays) {
                maxConsecutiveDays = currentStreak;
              }
            } else {
              currentStreak = 1;
            }
          }

          setTotalMeditationMinutes(totalMinutes);
          setAverageMeditationMinutes(averageMinutes);
          setCompletedSessions(sessions.length);
          setConsecutiveDays(maxConsecutiveDays);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching user sessions:', error);
          setLoading(false);
        }
      }
    };

    fetchUserSessions();
  }, [currentUser]);

  const formatTime = (minutes) => {
    if (minutes < 60) {
      return `${minutes} minutes`;
    }
    const hours = (minutes / 60).toFixed(1);
    return `${hours} heures`;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', bgcolor: 'background.default', mb:2 }}>
      <Typography variant="h6" sx={{ mb: 0 }}>Statistiques</Typography>
      <List sx={{ width: '100%', bgcolor: 'background.paper', p: 0 }}>
        <ListItem sx={{ p: 0 }}>
          <ListItemIcon sx={{ minWidth: '0', mr: 2 }}>
            <HourglassEmptyRoundedIcon sx={{ color: 'primary.main' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              loading ? <Skeleton animation="wave" variant="text" width={210} height={25} /> : (
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {`${averageMeditationMinutes.toFixed(1)} minutes`}
                </Typography>
              )
            }
            secondary={
              loading ? <Skeleton variant="text" width={210} /> : (
                <Typography variant="body2">
                  Durée moyenne de méditation
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemIcon sx={{ minWidth: '0', mr: 2 }}>
            <AccessTimeRoundedIcon sx={{ color: 'primary.main' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              loading ? <Skeleton animation="wave" variant="text" width={210} height={26} /> : (
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {formatTime(totalMeditationMinutes)}
                </Typography>
              )
            }
            secondary={
              loading ? <Skeleton animation="wave" variant="text" width={210} /> : (
                <Typography variant="body2">
                  Temps total de méditation
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemIcon sx={{ minWidth: '0', mr: 2 }}>
            <EventNoteRoundedIcon sx={{ color: 'primary.main' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              loading ? <Skeleton animation="wave" variant="text" width={210} height={26} /> : (
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {`${completedSessions} séances`}
                </Typography>
              )
            }
            secondary={
              loading ? <Skeleton animation="wave" variant="text" width={210} /> : (
                <Typography variant="body2">
                  Séances terminées
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem sx={{ p: 0 }}>
          <ListItemIcon sx={{ minWidth: '0', mr: 2 }}>
            <EmojiFlagsRoundedIcon sx={{ color: 'primary.main' }} />
          </ListItemIcon>
          <ListItemText
            primary={
              loading ? <Skeleton animation="wave" variant="text" width={210} height={25} /> : (
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {`${consecutiveDays} jours`}
                </Typography>
              )
            }
            secondary={
              loading ? <Skeleton animation="wave" variant="text" width={210} /> : (
                <Typography variant="body2">
                  Jours de méditation consécutifs
                </Typography>
              )
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default Statistics;
