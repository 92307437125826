// src/components/profile/ForgotPasswordModal.js
import React, { useState } from 'react';
import { Box, Button, Modal, TextField, Typography, Alert } from '@mui/material';

const ForgotPasswordModal = ({ open, handleClose, handleSendResetLink }) => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleSendResetLink(email);
      setSuccessMessage('Un lien vient de vous être envoyé par email.');
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Erreur lors de l\'envoi du lien de réinitialisation.');
      setSuccessMessage('');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: '80%'
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
          Réinitialiser votre mot de passe
        </Typography>
        <Typography id="modal-description" variant="body2" color="textSecondary" sx={{ mb: 0 }} gutterBottom>
          Entrez votre adresse email pour recevoir un lien de réinitialisation.
        </Typography>
        {successMessage && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {successMessage}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Adresse email"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            sx={{ mt: 2 }}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Envoyer
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ForgotPasswordModal;
