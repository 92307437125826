// /components/profile/friends/ManageRequests.js
import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, IconButton, Typography, Button, Avatar, Alert, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../../contexts/AuthContext';

const ManageRequests = () => {
  const { currentUser, acceptFriendRequest, rejectFriendRequest, refreshUser } = useAuth();
  const [friendRequests, setFriendRequests] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.friendRequests) {
      setFriendRequests(currentUser.friendRequests);
    }
  }, [currentUser]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Disparition après 3 secondes

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleAccept = async (friendId) => {
    try {
      await acceptFriendRequest(friendId);
      await refreshUser();
      setAlertMessage('Demande d\'ami acceptée');
      setShowAlert(true);
    } catch (error) {
      console.error('Erreur lors de l\'acceptation de la demande d\'ami:', error);
    }
  };
  
  const handleReject = async (friendId) => {
    try {
      await rejectFriendRequest(friendId);
      await refreshUser();
      setAlertMessage('Demande d\'ami refusée');
      setShowAlert(true);
    } catch (error) {
      console.error('Erreur lors du refus de la demande d\'ami:', error);
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Collapse in={showAlert}>
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          {alertMessage}
        </Alert>
      </Collapse>
      <List sx={{ p: 0 }}>
        {friendRequests.length > 0 ? (
          friendRequests.map((request, index) => (
            <ListItem sx={{ p: 0 }} key={index}>
              <Avatar alt={request.username} src={request.avatar} sx={{ mr: 2, border: '1px solid #ddd' }} />
              <ListItemText 
                primary={request.firstName || 'Utilisateur'} 
                secondary={request.username ? `@${request.username}` : ''} 
              />
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleAccept(request._id)} 
                sx={{ mr: 1, width: 'fit-content' }}
              >
                Accepter
              </Button>
              <IconButton 
                color="primary" 
                onClick={() => handleReject(request._id)} 
                sx={{ border: '1px solid #ddd' }}
              >
                <CloseIcon />
              </IconButton>
            </ListItem>
          ))
        ) : (
          <Typography variant='body2' align="center" sx={{ mt: 2, color: 'text.secondary' }}>
            Aucune demande d'ami en attente
          </Typography>
        )}
      </List>
    </Box>
  );
};

export default ManageRequests;
