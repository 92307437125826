import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, CardContent, Typography, Button, AppBar, Toolbar, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ZenSpinner from '../components/ZenSpinner';
import { useTheme } from '@mui/material/styles';
import AudioPlayerComponent from '../components/AudioPlayerComponent';
import DOMPurify from 'dompurify';
import useDeviceInfo from '../utils/useDeviceInfo';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const MeditationPage = () => {
  const { meditationId } = useParams();
  const [meditationDetails, setMeditationDetails] = useState(null);
  const navigate = useNavigate();
  const [showPlayer, setShowPlayer] = useState(false);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const theme = useTheme();

  useEffect(() => {
    const fetchMeditationDetails = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/meditations/${meditationId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails de la méditation');
        }
        const data = await response.json();
        setMeditationDetails(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMeditationDetails();
  }, [meditationId]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleStart = () => {
    setShowPlayer(true);
  };

  if (!meditationDetails) {
    return <ZenSpinner />;
  }

  const cleanDescriptionHtml = DOMPurify.sanitize(meditationDetails.description || '');

  return (
    <Box sx={{ display: 'flex', height: '90vh', flexDirection: 'column' }}>
      {/* Barre d'outils avec bouton de retour */}
      <AppBar position="static" color="secondary" sx={{ border: '0px', boxShadow:'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px:2 }}>
          <IconButton
            size="small"
            color="black"
            sx={{
              p: 0.5,
              backgroundColor: 'white',
              borderRadius: '50%',
              border:'1px solid #fff',
              mr: 2,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Image de la méditation */}
      <Box sx={{ backgroundColor: 'secondary.main', display: 'flex' }}>
        {meditationDetails.image ? (
          <img src={meditationDetails.image} alt={meditationDetails.title} style={{ borderRadius: '4px', width: '100%', flexShrink: 0 }} />
        ) : (
          <Box sx={{ borderRadius: '4px', width: '100%', height: 200, bgcolor: theme.palette.secondary.main, flexShrink: 0 }} />
        )}
      </Box>

      {/* Détails de la méditation */}
      <Box sx={{ flexGrow: 1, overflow: 'auto', pt: 0.5, pl: 3, pr: 3, mt: 2 }}>
        <CardContent sx={{ p: 0 }}>
          <Typography gutterBottom variant="h5" sx={{ mt: 1, mb: 0.6 }}>
            {meditationDetails.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
              <AccessTimeFilledIcon sx={{ fontSize: 14 }} />
            </Box>
            <Box sx={{ paddingTop: '2px' }}>{`${meditationDetails.duration} minutes`}</Box>
          </Typography>
          {/* Description de la méditation */}
          <Typography variant="body1" sx={{ mt: 1.5, mb: 2 }} dangerouslySetInnerHTML={{ __html: cleanDescriptionHtml }} />

          {/* Bouton pour démarrer la méditation */}
          <Button variant="contained" startIcon={<PlayArrowIcon />} sx={{ width: '100%' }} onClick={handleStart}>
            <Box sx={{ paddingTop: '3px' }}>Démarrer</Box>
          </Button>
        </CardContent>
      </Box>

      {/* Lecteur audio */}
      {showPlayer && (
        <AudioPlayerComponent
          audioSrc={meditationDetails.audioUrl}
          title={meditationDetails.title}
          contentType="meditation"
          onClose={() => setShowPlayer(false)}
        />
      )}
    </Box>
  );
};

export default MeditationPage;
