// utils/emailUtils.js
const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  
  const formatEmail = (email) => {
    // Convertir en minuscule et enlever les accents
    return removeAccents(email.toLowerCase());
  };
  
  module.exports = { formatEmail };
  