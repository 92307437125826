import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Alert, Collapse } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';

const AddFriend = () => {
  const { sendFriendRequest } = useAuth();
  const [friendIdentifier, setFriendIdentifier] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
        setError('');
        setSuccess('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleAddFriend = async () => {
    setError('');
    setSuccess('');
    setShowAlert(false);
    try {
      const response = await sendFriendRequest(friendIdentifier);
      if (response.error) {
        throw new Error(response.message || 'Failed to send friend request');
      }
      setSuccess('Demande d\'ami envoyée avec succès.');
      setFriendIdentifier('');
      setShowAlert(true);
    } catch (error) {
      setError(`Erreur lors de l'envoi de la demande d'ami: ${error.message}`);
      setShowAlert(true);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <TextField
          label="Nom d'utilisateur"
          variant="outlined"
          fullWidth
          value={friendIdentifier}
          onChange={(e) => setFriendIdentifier(e.target.value)}
          sx={{ mr: 1 }}
        />
        <Button sx={{ width: 'fit-content' }} variant="contained" color="primary" onClick={handleAddFriend}>
          Ajouter
        </Button>
      </Box>
      <Box>
        <Collapse in={showAlert}>
          {error && <Alert severity="error" onClose={() => setShowAlert(false)}>{error}</Alert>}
          {success && <Alert severity="success" onClose={() => setShowAlert(false)}>{success}</Alert>}
        </Collapse>
      </Box>
    </Box>
  );
};

export default AddFriend;
