import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import BuildIcon from '@mui/icons-material/Build';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { useNavigate } from 'react-router-dom';

function AdminNavBarComponent() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { text: 'Utilisateurs', icon: <PeopleIcon />, path: '/admin/users' },
    { text: 'Programmes', icon: <MenuBookIcon />, path: '/admin/programs' },
    { text: 'Méditations', icon: <SelfImprovementIcon />, path: '/admin/meditations' },
    { text: 'Techniques', icon: <BuildIcon />, path: '/admin/techniques' },
    { text: 'Citation', icon: <FormatQuoteIcon />, path: '/admin/quotes' },
  ];

  const handleItemClick = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setDrawerOpen(true)}
            sx={{ marginRight: 1 }} // Ajoute un espace entre le bouton et le titre
          >
            <MenuIcon />
          </IconButton>
          {/* Ajout du titre à côté du bouton burger */}
          <Typography variant="body" component="div" sx={{ flexGrow: 1, pt:0.5, fontWeight:'bold' }}>
            Tableau de Bord
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        // Ajoute un padding à droite du contenu du Drawer
        sx={{
          width: 240,
          '& .MuiDrawer-paper': { width: 220, boxSizing: 'border-box', pt: 1 },
        }}
      >
        <List>
          {menuItems.map((item, index) => (
            <ListItem button key={item.text} onClick={() => handleItemClick(item.path)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      {/* Ajouter un padding en haut pour compenser la AppBar */}
      <Toolbar />
    </div>
  );
}

export default AdminNavBarComponent;
