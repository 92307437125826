import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AcceptInvitationPage = () => {
  const { token } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [inviterName, setInviterName] = useState('');

  useEffect(() => {
    const fetchInviter = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${backendUrl}/api/invitations/inviter/${token}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Erreur lors de la récupération des informations de l\'inviteur.');
            }

            setInviterName(`${data.inviter.firstName}`);
        } catch (err) {
            console.error('Erreur lors de la récupération de l\'inviteur :', err.message);
            setError('Impossible de récupérer les informations de l\'inviteur.');
        } finally {
            setLoading(false);
        }
    };

    fetchInviter();
}, [token]);


  const handleAccept = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${backendUrl}/api/invitations/accept/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ userId: currentUser.userId }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Erreur lors de la confirmation de l\'invitation.');
      }

      setSuccess('Invitation acceptée avec succès.');
      setError('');
      setLoading(false);

      navigate('/status');
    } catch (err) {
      console.error('Erreur lors de l\'acceptation de l\'invitation :', err.message);
      setError('Impossible d\'accepter l\'invitation.');
      setSuccess('');
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${backendUrl}/api/invitations/decline/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Erreur lors du refus de l\'invitation.');
      }

      setSuccess('Invitation refusée avec succès.');
      setError('');
    } catch (err) {
      console.error('Erreur lors du refus de l\'invitation :', err.message);
      setError('Impossible de refuser l\'invitation.');
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'secondary.main', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', px: 2 }}>
      <Box component="img" src="/img/duo.svg" alt="Invitation Duo" width="74vw" />

      {loading && <CircularProgress sx={{ mt: 3 }} />}

      {!loading && (
        <Box textAlign="center" mt={3} width="70%">
          {success && <Typography color="primary" variant="h6">{success}</Typography>}
          {error && <Typography color="error" variant="h6">{error}</Typography>}

          {!success && !error && (
            <>
              <Typography variant="h5" mb={1}>{inviterName ? `${inviterName} t'invite à rejoindre son duo` : 'Invitation reçue'}</Typography>

              <Typography variant="body1" mb={1}>
                  En acceptant, tu profiteras de l’abonnement complet.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                onClick={handleAccept}
                sx={{ mt: 2, width: '100%' }}
              >
                Accepter
              </Button>
              <Button
                variant="outlined"
                onClick={handleDecline}
                sx={{ mt: 1, width: '100%' }}
              >
                Refuser
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AcceptInvitationPage;