import React from 'react';
import { Box, Typography } from '@mui/material';
import AdminNavBarComponent from '../../components/admin/AdminNavBarComponent'; // Ajuste le chemin selon l'organisation de ton projet
import UserManagementComponent from '../../components/admin/UserManagementComponent'; // Ajuste le chemin selon l'organisation de ton projet

const AdminUsers = () => {
  return (
    <Box>
        <AdminNavBarComponent />
    <UserManagementComponent />
</Box>
  );
};

export default AdminUsers;
