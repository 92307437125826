import React from 'react';
import { Box, Typography } from '@mui/material';
import AdminNavBarComponent from '../../components/admin/AdminNavBarComponent'; // Ajuste le chemin selon l'organisation de ton projet
import QuoteManagementComponent from '../../components/admin/QuoteManagementComponent'; // Ajuste le chemin selon l'organisation de ton projet

const AdminPrograms = () => {
  return (
    <Box>
        <AdminNavBarComponent />
    <QuoteManagementComponent />
</Box>
  );
};

export default AdminPrograms;
