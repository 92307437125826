import React, { useEffect, useState } from 'react';
import { Box, Fade } from '@mui/material';
import WelcomeSlide from '../../components/onboarding/WelcomeSlide';
import FinalSlide from '../../components/onboarding/FinalSlide';

const tutorialSteps = [
  { component: <WelcomeSlide />, duration: 2000 },
  { component: <FinalSlide />, duration: 0 },
];

const SuccessPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [fade, setFade] = useState(true);
  const maxSteps = tutorialSteps.length;

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setActiveStep((prevStep) => {
          if (prevStep === maxSteps - 1) {
            clearInterval(interval);
            return prevStep;
          }
          return prevStep + 1;
        });
        setFade(true);
      }, 500); // 1 second fade out
    }, tutorialSteps[activeStep].duration);

    return () => clearInterval(interval);
  }, [activeStep, maxSteps]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw', textAlign: 'center', padding: 0, position: 'relative' }}>
      <Box sx={{ height: '100vh', width: '100vw' }}>
        <Fade in={fade} timeout={{ enter: 500, exit: 500 }}>
          <Box>
            {tutorialSteps[activeStep].component}
          </Box>
        </Fade>
      </Box>
    </Box>
  );
};

export default SuccessPage;
