import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Button, TextField, IconButton, List, ListItem, ListItemAvatar, Drawer, Alert, Snackbar, Skeleton } from '@mui/material';
import CelebrationRoundedIcon from '@mui/icons-material/CelebrationRounded';
import ModeCommentRoundedIcon from '@mui/icons-material/ModeCommentRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import QuoteCard from './QuoteCard';
import { useAuth } from '../../contexts/AuthContext';
import useDeviceInfo from '../../utils/useDeviceInfo';

const Feed = () => {
  const { currentUser } = useAuth();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);  // Ajouter l'état loading
  const [visiblePosts, setVisiblePosts] = useState(8); // Pagination
  const [selected, setSelected] = useState(null);
  const [commentsDrawerOpen, setCommentsDrawerOpen] = useState(false);
  const [currentComments, setCurrentComments] = useState([]);
  const [quotePosition, setQuotePosition] = useState(null);
  const [error, setError] = useState(null);
  const [newComments, setNewComments] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { paddingTop, paddingBottom } = useDeviceInfo();

  // Fetch posts
  useEffect(() => {
    const fetchFeedData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/feed`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data = await response.json();
        setPosts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);  // Arrêter le chargement
      }
    };

    fetchFeedData();
  }, []);

  useEffect(() => {
    const randomPosition = Math.floor(Math.random() * 2) + 1;
    setQuotePosition(randomPosition);
  }, []);

  // Fonction pour gérer l'ouverture du drawer avec les commentaires
  const handleOpenComments = (comments) => {
    setCurrentComments(comments);
    setCommentsDrawerOpen(true);
  };

  // Fonction pour gérer l'ajout de likes
  const handleLikePost = async (postId, alreadyLiked) => {
    if (alreadyLiked) {
      setAlertMessage('Vous avez déjà liké ce post.');
      setOpenAlert(true);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/like/${postId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setAlertMessage(errorData.message);
        setOpenAlert(true);
        return;
      }

      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId ? { ...post, likes: [...post.likes, currentUser._id] } : post
        )
      );
    } catch (error) {
      setError(error.message);
    }
  };

  // Fonction pour gérer l'ajout de commentaires
  const handleAddComment = async (postId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/comment/${postId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ comment: newComments[postId] || '' }),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }

      const updatedPost = await response.json();
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === postId ? { ...post, comments: updatedPost.comments } : post
        )
      );

      setNewComments((prev) => ({ ...prev, [postId]: '' }));
      setSelected(null); // Réinitialiser l'état sélectionné
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCommentChange = (postId, value) => {
    setNewComments((prev) => ({ ...prev, [postId]: value }));
  };

  const handleSubmitComment = (postId) => {
    handleAddComment(postId);
  };

  const handleKeyDown = (event, postId) => {
    if (event.key === 'Enter') {
      handleSubmitComment(postId);
    }
  };

  const formatRelativeDate = (date) => {
    const today = new Date();
    const eventDate = new Date(date);

    today.setUTCHours(0, 0, 0, 0);
    eventDate.setUTCHours(0, 0, 0, 0);

    const diffTime = today - eventDate;
    const diffDays = Math.max(Math.floor(diffTime / (1000 * 60 * 60 * 24)), 0);

    if (diffDays === 0) {
      return "Aujourd'hui";
    } else if (diffDays === 1) {
      return 'Hier';
    } else {
      return `Il y a ${diffDays} jours`;
    }
  };

  const loadMorePosts = () => {
    setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 8);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {error && <Typography color="error">{error}</Typography>}
      <List sx={{ p: 0 }}>
        {loading ? (
          // Afficher les Skeletons pendant le chargement
          Array.from(new Array(8)).map((_, index) => (
            <Box key={index} sx={{ mb: 2, bgcolor: 'background.paper', borderRadius: 2, border: '1px solid #DDD', overflow: 'hidden' }}>
              <ListItem sx={{ px: 2, pt: 2 }}>
                <ListItemAvatar>
                  <Skeleton variant="circular" width={44} height={44} />
                </ListItemAvatar>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Skeleton variant="text" width="30%" height={24} />
                  <Skeleton variant="text" width="20%" height={18} />
                </Box>
              </ListItem>
              <Box sx={{ px: 2, mb: 1.5, mt: 1 }}>
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="80%" />
              </Box>
              <Box sx={{ px: 2, mb: 2 }} display="flex" justifyContent="space-between" alignItems="center" mt={0}>
                <Skeleton variant="rectangular" width="80px" height="36px" />
                <Skeleton variant="text" width="30px" />
              </Box>
            </Box>
          ))
        ) : (
          posts.slice(0, visiblePosts).map((post, index) => (
            <React.Fragment key={post._id}>
              {quotePosition === index && <QuoteCard />}

              <Box sx={{ mb: 2, bgcolor: 'background.paper', borderRadius: 2, border: '1px solid #DDD', overflow: 'hidden' }}>
                <ListItem sx={{ px: 2, pt: 2 }}>
                  <ListItemAvatar>
                    <Avatar src={post.userId?.avatar} alt={`${post.userId?.firstName} ${post.userId?.lastName}`} sx={{ border: '1px solid #DDD', height: 44, width: 44 }} />
                  </ListItemAvatar>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 'bold', lineHeight: '1em', mb: 1 }} variant='body'>
                      {`${post.userId?.firstName} ${post.userId?.lastName}`}
                    </Typography>
                    <Typography sx={{ lineHeight: '1em', fontSize: '0.8rem', color: 'gray' }} variant='body2'>
                      {formatRelativeDate(post.createdAt)}
                    </Typography>
                  </Box>
                </ListItem>

                <Box sx={{ px: 2, mb: 1.5, mt: 1 }}>
                  <Typography variant='body'>{post.description}</Typography>
                </Box>

                <Box sx={{ px: 2, mb: 2 }} display="flex" justifyContent="space-between" alignItems="center" mt={0}>
                  <Button
                    startIcon={<CelebrationRoundedIcon />}
                    sx={{ width: 'fit-content' }}
                    variant="contained"
                    color='secondary'
                    onClick={() => handleLikePost(post._id, post.likes.includes(currentUser._id))}
                  >
                    Encourager
                  </Button>
                  <Box display="flex" alignItems="center">
                    <CelebrationRoundedIcon fontSize="0.8em" />
                    <Typography variant="body2" sx={{ ml: 0.5 }}>{post.likes.length}</Typography>
                  </Box>
                </Box>

                <Box sx={{ borderTop: '1px solid #DDD' }}>
                  {post.comments.length > 0 && (
                    <Box onClick={() => handleOpenComments(post.comments)} sx={{ mt: 2, px: 2, display: 'flex', justifyContent: 'space-between' }}>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1 }}>
                          {post.comments[0].userId.firstName}
                        </Typography>
                        <Typography variant="body2">{post.comments[0].comment}</Typography>
                      </Box>

                      <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
                        <ModeCommentRoundedIcon sx={{ color: 'gray', fontSize: '0.8em' }} />
                        <Typography variant="body2" sx={{ ml: 0.6 }}>{post.comments.length}</Typography>
                      </Box>
                    </Box>
                  )}

                  <Box display="flex" alignItems="center" mt={0.5} sx={{ px: 2 }}>
                    <TextField
                      variant="standard"
                      sx={{ py: 1 }}
                      placeholder="Ajouter un commentaire..."
                      size="small"
                      fullWidth
                      value={newComments[post._id] || ''}
                      onChange={(e) => handleCommentChange(post._id, e.target.value)}
                      onFocus={() => setSelected(index)}
                      onBlur={() => setSelected(null)}
                      onKeyDown={(event) => handleKeyDown(event, post._id)}
                      InputProps={{
                        disableUnderline: true,
                        style: { fontSize: '0.875rem' },
                      }}
                    />
                    {selected === index && (
                      <IconButton
                        sx={{ ml: 1, color: 'gray', fontSize: '1em' }}
                        onMouseDown={(e) => e.preventDefault()} // Empêche la perte de focus lors du clic
                        onClick={() => handleSubmitComment(post._id)} // Envoie le commentaire
                      >
                        <SendRoundedIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Box>
            </React.Fragment>
          ))
        )}
      </List>

      {visiblePosts < posts.length && (
        <Box display="flex" justifyContent="center" sx={{ mt: 0 }}>
          <Button variant="text" sx={{ width: 'fit-content' }} onClick={loadMorePosts}><MoreHorizRoundedIcon /></Button>
        </Box>
      )}

      <Drawer
        anchor="bottom"
        open={commentsDrawerOpen}
        onClose={() => setCommentsDrawerOpen(false)}
      >
        <Box sx={{ px: 3, pt: 2, pb: 5 }}>
          <Typography variant="h6">Commentaires</Typography>
          {currentComments.map((comment, index) => (
            <Box key={index} sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
              <Avatar src={comment.userId.avatar} alt={comment.userId.firstName} sx={{ width: 40, height: 40, mr: 2, border: '1px solid #DDD' }} />
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 1, lineHeight: '1em' }}>
                    {comment.userId.firstName} {comment.userId.lastName}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', color: 'gray', lineHeight: '1em' }}>
                    {formatRelativeDate(comment.createdAt)}
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ lineHeight: '1em' }}>
                  {comment.comment}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Drawer>

      <Snackbar sx={{ pb: paddingBottom }} open={openAlert} autoHideDuration={4000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Feed;
