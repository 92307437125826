import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const Advices = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Conseils pour bien commencer la méditation
      </Typography>

      <Typography variant="body1" paragraph>
        La méditation est une pratique accessible à tous, qui peut profondément transformer ton bien-être mental et émotionnel. Voici quelques conseils pratiques pour t'aider à démarrer avec sérénité.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        1. Trouve un endroit calme
      </Typography>
      <Typography variant="body2" paragraph>
        Choisis un espace tranquille où tu ne seras pas dérangé, que ce soit une pièce dédiée ou simplement un coin de ta maison. L'environnement doit être propice à la détente et à la concentration.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        2. Commence doucement
      </Typography>
      <Typography variant="body2" paragraph>
        Inutile de méditer pendant des heures. Commence par 5 à 10 minutes par jour pour bâtir une habitude régulière et durable. L'essentiel est la constance, pas la durée.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        3. Concentre-toi sur ta respiration
      </Typography>
      <Typography variant="body2" paragraph>
        Ta respiration est un excellent point d'ancrage. Observe-la sans chercher à la modifier. Chaque fois que ton esprit vagabonde, ramène doucement ton attention sur l'inspiration et l'expiration.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        4. Accueille tes pensées sans jugement
      </Typography>
      <Typography variant="body2" paragraph>
        Il est normal que des pensées surgissent pendant la méditation. Plutôt que de les combattre, accepte-les et laisse-les passer comme des nuages dans le ciel. Ne te juge pas si ton esprit divague, c'est tout à fait naturel.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        6. Explore différentes techniques
      </Typography>
      <Typography variant="body2" paragraph>
        Au-delà de la respiration, tu peux essayer d'autres techniques comme le scan corporel, où tu concentres ton attention sur chaque partie de ton corps, ou la méditation en marchant, qui combine mouvement et pleine conscience.
      </Typography>

    </Box>
  );
};

export default Advices;
