import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Card, CardActionArea, CardContent, AppBar, Toolbar, IconButton, CardMedia, Typography, Grid, useTheme, Skeleton, Button, Drawer } from '@mui/material';
import Lottie from 'react-lottie-player';
import pulseAnimation from '../assets/pulse.json'; 
import useDeviceInfo from '../utils/useDeviceInfo';
import TopMeditations from '../components/meditations/TopMeditations';
import ZenSpinner from '../components/ZenSpinner';
import { Device } from '@capacitor/device';
import Advices from '../components/meditations/Advices'; // Import du composant Advices
import CloseIcon from '@mui/icons-material/Close';

const TemplePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const [isAdviceOpen, setIsAdviceOpen] = useState(false);
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    const checkPlatform = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        setIsIos(true);
      }
    };

    checkPlatform();
  }, []);

  const handleOpenAdvices = () => {
    setIsAdviceOpen(true); // Ouvre le drawer des conseils
  };

  const handleCloseAdvices = () => {
    setIsAdviceOpen(false); // Ferme le drawer des conseils
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Box>
      {isLoading ? (
        <ZenSpinner />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
        <Grid container spacing={1} justifyContent="center">
          
          {/* Ajout du bandeau fullwidth */}
          <Box sx={{ width: '100%', position: 'relative', overflow: 'hidden', mb: 3, height: isIos ? '22vh' : '18vh'}}>
            {/* Animation Lottie en arrière-plan */}
            <Box sx={{ position: 'absolute', width: '100%', height: '300px', top: 0, zIndex: -1, bgcolor:'secondary.main' }}>
              <Lottie
                loop
                animationData={pulseAnimation}
                play
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            <img src="/img/thibaut.svg" alt="Tibo" style={{ width: 130, height:130 , position:'absolute', bottom: 0, transform: 'translateX(-50%)', left: '50%' }} />
          </Box>

          {/* Texte et bouton */}
          <Box sx={{ mb:4, textAlign:'center' }}>
            <Typography variant='h5'>Commencer à méditer</Typography>
            <Typography variant='body2' >Quelques conseils de Thibaut</Typography>
            <Button
              variant="contained"
              sx={{ width: 'fit-content', mt: 1, height: '40px' }}
              onClick={handleOpenAdvices} // Ouvre le drawer des conseils
            >
              Lire les conseils
            </Button>
          </Box>

      {/* Drawer pour afficher les conseils */}
      <Drawer
        anchor="bottom"
        open={isAdviceOpen}
        onClose={handleCloseAdvices}
      >
        {/* AppBar avec bouton de fermeture */}
        <AppBar position="sticky" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Toolbar sx={{ pt: paddingTop + 1 }}>
            <IconButton
              size="small"
              sx={{
                p: 0.5,
                border: '1px solid #DDD',
                backgroundColor: 'white',
                borderRadius: '50%',
                mr: 2,
              }}
              aria-label="fermer"
              onClick={handleCloseAdvices}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/* Contenu des conseils */}
        <Box>
          <Advices />
        </Box>
      </Drawer>

          <Box sx={{px:4 }} >

          <TopMeditations />

          <Box sx={{ display:'flex', flexDirection: 'column', alignItems: 'center', pb: paddingBottom + 12 }} >

              {/* Méditations */}
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', mb: 1.5, mr:0 }}>
                <Card elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '6px', mr:0, backgroundColor: theme.palette.secondary.main }}>
                  <CardActionArea sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 1 }} onClick={() => navigate('/meditation')}>
                    {isLoading ? (
                      <Skeleton variant="rectangular" height={150} animation="wave" width="100%" />
                    ) : (
                      <>
                        <CardContent sx={{ textAlign: 'left', flex: 1 }}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ mb: 0 }}>
                            Méditations
                          </Typography>
                          <Typography gutterBottom variant="body2" sx={{ mb: 0 }}>
                            Une sélection parfaite pour s'accorder un moment.
                          </Typography>
                        </CardContent>
                        <CardMedia component="img" image="/img/cat-min.svg" alt="Méditations" sx={{ height: '120px', width: '120px', objectFit: 'cover', alignSelf: 'flex-end' }} />
                      </>
                    )}
                  </CardActionArea>
                </Card>
              </Grid>

              {/* Techniques */}
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', mb: 1.5 }}>
                <Card elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '6px', backgroundColor: theme.palette.secondary.main }}>
                  <CardActionArea sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 1 }} onClick={() => navigate('/technique')}>
                    {isLoading ? (
                      <Skeleton variant="rectangular" height={150} animation="wave" width="100%" />
                    ) : (
                      <>
                        <CardContent sx={{ textAlign: 'left', flex: 1 }}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ mb: 0 }}>
                            Techniques
                          </Typography>
                          <Typography gutterBottom variant="body2" sx={{ mb: 0 }}>
                            Méditations de base pour s'entrainer au quotidien.
                          </Typography>
                        </CardContent>
                        <CardMedia component="img" image="/img/fox-min.svg" alt="Techniques" sx={{ height: '120px', width: '120px', objectFit: 'cover', alignSelf: 'flex-end' }} />
                      </>
                    )}
                  </CardActionArea>
                </Card>
              </Grid>

              {/* Chronomètre */}
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Card elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '6px', backgroundColor: theme.palette.secondary.main }}>
                  <CardActionArea sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 1 }} onClick={() => navigate('/timer')}>
                    {isLoading ? (
                      <Skeleton variant="rectangular" height={150} animation="wave" width="100%" />
                    ) : (
                      <>
                        <CardContent sx={{ textAlign: 'left', flex: 1 }}>
                          <Typography gutterBottom variant="h6" component="div" sx={{ mb: 0 }}>
                            Chronomètre
                          </Typography>
                          <Typography gutterBottom variant="body2" sx={{ mb: 0 }}>
                            Sélectionne la durée et l'ambiance de ton choix.
                          </Typography>
                        </CardContent>
                        <CardMedia component="img" image="/img/monkey-min.svg" alt="Chronomètre" sx={{ height: '120px', width: '120px', objectFit: 'cover', alignSelf: 'flex-end' }} />
                      </>
                    )}
                  </CardActionArea>
                </Card>
              </Grid>

          </Box>

          </Box>
          </Grid>
      </Box>
    )}
  </Box>
);
};

export default TemplePage;
