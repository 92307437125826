import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography, AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Pour rediriger vers /session/:sessionId
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import pulseAnimation from '../assets/pulse.json';
import Lottie from 'react-lottie-player';
import { useAuth } from '../contexts/AuthContext'; 
import useDeviceInfo from '../utils/useDeviceInfo';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AudioPlayerComponent = ({ audioSrc, title, category, meditationOrder, onClose, contentType }) => {
  const { currentUser } = useAuth(); 
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false); 
  const { paddingTop, paddingBottom } = useDeviceInfo();
  
  const navigate = useNavigate(); // Hook pour rediriger
  const audioRef = useRef(null); // Utiliser une référence pour l'audio

  useEffect(() => {
    // Initialiser l'audio lorsqu'il y a un src valide
    if (audioSrc) {
      const audio = new Audio(audioSrc);
      audioRef.current = audio; // Sauvegarder l'audio dans la référence
      audio.addEventListener('loadeddata', () => {
        setDuration(audio.duration);
        console.log('Audio chargé, durée :', audio.duration); // Log pour vérifier la durée
      });

      audio.addEventListener('timeupdate', () => {
        setCurrentTime(audio.currentTime);
        setProgress((audio.currentTime / audio.duration) * 100);
      });

      audio.addEventListener('ended', () => {
        console.log('Audio terminé, enregistrement de la session...');
        saveMeditationSession(); // Sauvegarder la session et rediriger
      });

      return () => {
        audio.removeEventListener('loadeddata', () => {});
        audio.removeEventListener('timeupdate', () => {});
        audio.removeEventListener('ended', () => {});
        audio.pause();
        audio.currentTime = 0;
      };
    }
  }, [audioSrc]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setShowAnimation(false); 
    } else {
      audioRef.current.play();
      setShowAnimation(true); 
    }
    setIsPlaying(!isPlaying);
  };

  const handleClose = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (onClose) {
      onClose();
    }
  };

  const saveMeditationSession = async () => {
    if (currentUser) {
      const sessionData = {
        userId: currentUser.userId,
        listenedDuration: Math.round(currentTime / 60),
        sessionType: 'solo' 
      };
  
      try {
        console.log('Enregistrement de la session...', sessionData); // Vérification des données de la session
        const response = await fetch(`${backendUrl}/api/meditation-sessions/sessions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(sessionData),
        });
  
        const result = await response.json(); // Obtenir les détails de la session
        console.log('Réponse du backend', result); // Vérification de la réponse du backend
  
        if (!response.ok) {
          throw new Error(result.message || 'Erreur lors de la sauvegarde de la session');
        }
  
        const sessionId = result.session?._id; // Accès à l'ID de la session à partir de l'objet renvoyé par le backend
        if (!sessionId) {
          throw new Error('ID de session non trouvé');
        }
  
        console.log('Session sauvegardée avec succès, ID de la session :', sessionId); // Log de l'ID de la session
        navigate(`/session/${sessionId}`); // Rediriger vers la nouvelle page avec l'ID de la session
      } catch (error) {
        console.error('Erreur lors de la sauvegarde de la session de méditation:', error.message);
      }
    }
  };
  
  return (
    <Box sx={{ width: '100vw', height: '100vh',  bgcolor: 'secondary.main', position: 'fixed', top: 0, left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {/* AppBar avec bouton de fermeture */}
      <AppBar position="fixed" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #fff',
              backgroundColor:'white',
              borderRadius: '50%',
              mr: 2,
            }}
            aria-label="fermer"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ position: 'absolute', color: 'black', top: '20%', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ mb: 1 }}>{title}</Typography>
      </Box>
      <IconButton
        onClick={togglePlayPause}
        sx={{
          zIndex: 1,
          color: isPlaying ? 'primary.main' : 'black', 
          bgcolor: 'transparent',
          position: 'relative',
          borderRadius: '50%'
        }}
      >
        {isPlaying ? <PauseIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
      </IconButton>
      <Box
        sx={{
          position: 'absolute',
          width: '600px',
          height: '600px',
          opacity: showAnimation ? 1 : 0, 
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <Lottie
          loop
          animationData={pulseAnimation}
          play={showAnimation}
          speed={0.5}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Typography variant="body" sx={{ mt: 3, color: 'black', position: 'absolute', bottom: 80 }}>
        {new Date(currentTime * 1000).toISOString().substr(14, 5)} - {new Date(duration * 1000).toISOString().substr(14, 5)}
      </Typography>
    </Box>
  );
};

export default AudioPlayerComponent;
