import { Box } from '@mui/material';

const FixedHeightContainer = ({ children }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    overflow: 'hidden',
    p: 2,
  }}>
    {children}
  </Box>
);

export default FixedHeightContainer;
