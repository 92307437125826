import React, { useState, useEffect } from 'react';
import {
  Button, Card, CardContent, Drawer, IconButton, Typography,
  Grid, Box, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const MeditationsManagementComponent = () => {
  const [meditations, setMeditations] = useState([]);
  const [techniques, setTechniques] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentMeditationToEdit, setCurrentMeditationToEdit] = useState(null);

  useEffect(() => {
    fetchMeditations();
    fetchTechniques();
  }, []);

  const fetchMeditations = async () => {
    const response = await fetch(`${backendUrl}/api/meditations`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setMeditations(data);
    } else {
      console.error('Erreur lors de la récupération des méditations');
    }
  };

  const fetchTechniques = async () => {
    const response = await fetch(`${backendUrl}/api/techniques`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setTechniques(data);
    } else {
      console.error('Erreur lors de la récupération des techniques');
    }
  };

  const handleEdit = (meditation) => {
    setCurrentMeditationToEdit(meditation);
    setIsDrawerOpen(true);
  };

  const handleDelete = async (meditationId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette méditation ?')) {
      const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/api/meditations/${meditationId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setMeditations(meditations.filter(meditation => meditation._id !== meditationId));
      } else {
        console.error('Erreur lors de la suppression de la méditation');
      }
    }
  };

  const handleAddMeditation = () => {
    setCurrentMeditationToEdit(null);
    setIsDrawerOpen(true);
  };

  const handleSaveMeditation = async (formData) => {
    const token = localStorage.getItem('token');
    let url = `${backendUrl}/api/meditations`;
    let method = 'POST';
    if (formData._id) {
      url += `/${formData._id}`;
      method = 'PUT';
    }
    const body = JSON.stringify(formData);

    const response = await fetch(url, {
      method,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.ok) {
      fetchMeditations(); // Re-fetch la liste des méditations pour refléter les changements
      setIsDrawerOpen(false);
    } else {
      console.error('Erreur lors de la sauvegarde de la méditation');
    }
  };

  const MeditationFormDrawer = ({ isOpen, onClose, meditation }) => {
    const [formData, setFormData] = useState({
      title: '',
      description: '',
      duration: 0,
      audioUrl: '',
      playCount: 0,
      techniquesUsed: [],
      ...meditation,
    });

    useEffect(() => {
      if (meditation) {
        setFormData(meditation);
      }
    }, [meditation]);

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value,
      }));
    };

    const handleChangeTechniquesUsed = (event) => {
      const {
        target: { value },
      } = event;
      setFormData({
        ...formData,
        techniquesUsed: typeof value === 'string' ? value.split(',') : value,
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      handleSaveMeditation(formData);
      onClose();
    };

    return (
      <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
        <Box p={2} component="form" onSubmit={handleSubmit}>
          <TextField
            label="Titre"
            name="title"
            value={formData.title || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            value={formData.description || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Durée (minutes)"
            name="duration"
            type="number"
            value={formData.duration || 0}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="URL Audio"
            name="audioUrl"
            value={formData.audioUrl || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="URL de l'Image"
            name="image"
            value={formData.image || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled
            label="Nombre de lectures"
            name="playCount"
            type="number"
            value={formData.playCount || 0}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="techniques-used-select-label">Techniques Utilisées</InputLabel>
            <Select
              labelId="techniques-used-select-label"
              id="techniques-used-select"
              multiple
              value={formData.techniquesUsed || []}
              onChange={handleChangeTechniquesUsed}
              input={<OutlinedInput label="Techniques Utilisées" />}
              renderValue={(selected) => selected.map(id => techniques.find(technique => technique._id === id)?.title).join(', ')}
            >
              {techniques.map((technique) => (
                <MenuItem key={technique._id} value={technique._id}>
                  <Checkbox checked={formData.techniquesUsed.indexOf(technique._id) > -1} />
                  <ListItemText primary={technique.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" sx={{ mt: 2 }} variant="contained">
            Sauvegarder
          </Button>
        </Box>
      </Drawer>
    );
  };

  return (
    <>
      <Box sx={{ margin: 2 }}>
        <Button startIcon={<AddIcon />} onClick={handleAddMeditation} variant="contained">
          Ajouter une méditation
        </Button>
      </Box>
      <Box sx={{ ml: 2, mr: 2, mb: 4 }}>
        <Grid container spacing={2}>
          {meditations.map(meditation => (
            <Grid item xs={12} sm={6} md={4} key={meditation._id}>
              <Card elevation={0} sx={{ pl: 1, pt: 1, borderRadius: '8px', border: '1px solid', borderColor: 'grey.300', overflow: 'hidden' }}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {meditation.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {meditation.description}
                  </Typography>
                  <Box>
                    <IconButton onClick={() => handleEdit(meditation)} size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(meditation._id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {isDrawerOpen && (
        <MeditationFormDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          meditation={currentMeditationToEdit}
        />
      )}
    </>
  );
};

export default MeditationsManagementComponent;