import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Slider, Switch, FormGroup, FormControlLabel, Tooltip } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext'; // Importer useAuth pour obtenir currentUser
import io from 'socket.io-client';

const backendUrl = process.env.REACT_APP_BACKEND_URL;
let socket = io(backendUrl);

const MeditationSettings = ({ roomCode, onSubmit }) => {
  const { currentUser } = useAuth();  // Utiliser useAuth pour obtenir currentUser
  const [meditationType, setMeditationType] = useState('guided');
  const [soundBackground, setSoundBackground] = useState(true);
  const [duration, setDuration] = useState(5);

  const allowedDurations = [5, 10, 20, 30];

  // Fonction pour arrondir à la valeur autorisée la plus proche
  const closestAllowedValue = (value) => {
    return allowedDurations.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
  };

  const handleSubmit = () => {
    const settings = {
      meditationType,
      soundBackground,
      duration
    };
  
    onSubmit(settings);
  
    // Vérifie que roomCode est bien passé
    console.log(`Émission de l'événement avec roomCode: ${roomCode}`);
  
    // Émettre l'événement 'inviteToMeditate' avec l'ID de la salle et l'utilisateur
    socket.emit('inviteToMeditate', { room: roomCode, senderId: currentUser.userId });
  
    socket.emit('updateMeditationSettings', { room: roomCode, settings });
  };
  

  return (
    <Box sx={{ p: 3 }}>

      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        {/* Type de Méditation avec Switch */}
        <FormControl component="fieldset" sx={{ mb: 2, textAlign: 'center' }}>
          <FormLabel component="legend">Méditation</FormLabel>
          <FormGroup>
            <Tooltip title="Activer pour passer à la méditation libre" arrow>
              <FormControlLabel
                control={
                  <Switch
                    checked={meditationType === 'free'}
                    onChange={() => setMeditationType(meditationType === 'guided' ? 'free' : 'guided')}
                  />
                }
                label={meditationType === 'guided' ? 'Guidé' : 'Libre'}
              />
            </Tooltip>
          </FormGroup>
        </FormControl>

        {/* Fond Sonore avec Switch */}
        <FormControl component="fieldset" sx={{ mb: 2, textAlign: 'center' }}>
          <FormLabel component="legend">Ambiance</FormLabel>
          <FormGroup>
            <Tooltip title="Activer ou désactiver le fond sonore" arrow>
              <FormControlLabel
                control={
                  <Switch
                    checked={soundBackground}
                    onChange={() => setSoundBackground(!soundBackground)}
                  />
                }
                label={soundBackground ? 'Avec' : 'Sans'}
              />
            </Tooltip>
          </FormGroup>
        </FormControl>
      </Box>

      {/* Sélecteur de durée avec Slider */}
      <FormControl fullWidth sx={{ mb: 3 }}>
        <Box sx={{ mx: 3 }}>
          <Slider
            value={duration}
            onChange={(e, newValue) => setDuration(newValue)}
            onChangeCommitted={(e, newValue) => setDuration(closestAllowedValue(newValue))}
            step={null} // Désactiver les petites étapes pour forcer uniquement les marques
            marks={allowedDurations.map(value => ({ value, label: `${value} min` }))}
            min={3}
            max={30}
          />
        </Box>
      </FormControl>

      <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
        Valider
      </Button>
    </Box>
  );
};

export default MeditationSettings;
