import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext'; // Utilisation du contexte Auth

const MeditationPrompt = ({ onStartMeditation }) => {
  const { currentUser } = useAuth(); // Récupère l'utilisateur actuel via le contexte
  const firstName = currentUser?.firstName || 'Utilisateur'; // Affiche "Utilisateur" par défaut

  return (
    <Box onClick={onStartMeditation} sx={{ bgcolor: 'secondary.main', p: 3, textAlign: 'center', borderRadius: '8px' }}>
      <Typography variant="h5" sx={{ mb: 1 }}>{firstName}, on médite ?</Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ width: 'auto' }}
      >
        C'est parti
      </Button>
    </Box>
  );
};

export default MeditationPrompt;
