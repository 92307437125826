// /src/components/room/UserManagementComponent.js
import React, { useState, useEffect } from 'react';
import {
  Button, Card, Avatar, Drawer, IconButton, Typography,
  Grid, Box, TextField, FormControl, InputLabel, Select, MenuItem, Checkbox,
  FormControlLabel, Tabs, Tab
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UserManagementComponent = () => {
  const [users, setUsers] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentUserToEdit, setCurrentUserToEdit] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${backendUrl}/api/users`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const usersData = await response.json();
      setUsers(usersData);
    } else {
      console.error('Erreur lors de la récupération des utilisateurs');
    }
  };

  const handleEdit = (user) => {
    setCurrentUserToEdit(user);
    setIsDrawerOpen(true);
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
      const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/api/users/delete/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setUsers(users.filter(user => user._id !== userId));
      } else {
        console.error('Erreur lors de la suppression de l\'utilisateur');
      }
    }
  };

  const handleAddUser = () => {
    setCurrentUserToEdit(null);
    setIsDrawerOpen(true);
  };

  const handleSaveUser = async (formData) => {
    const token = localStorage.getItem('token');
    let url = `${backendUrl}/api/users`;
    let method = 'POST';
    let body;

    if (formData._id) {
      // Lors de l'édition, incluez tous les champs sauf l'ID (et le mot de passe, s'il n'a pas été modifié ou inclus).
      url += `/${formData._id}`;
      method = 'PUT';
      // Excluez l'ID du formulaire des données envoyées.
      // Notez que nous ne reprenons pas le mot de passe ici.
      const { _id, password, ...fieldsToUpdate } = formData;
      body = JSON.stringify(fieldsToUpdate);
    } else {
      // Pour l'ajout, incluez tous les champs, y compris le mot de passe.
      url = `${backendUrl}/api/users/signup`;
      body = JSON.stringify({
        firstName: formData.firstName,
        lastName: formData.lastName, // Ajout du champ lastName
        email: formData.email,
        password: formData.password,
        phone: formData.phone,
        birthDate: formData.birthDate,
        avatar: formData.avatar, // Ajout du champ avatar
      });
    }

    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body
    });

    if (response.ok) {
      fetchUsers(); // Rechargez la liste des utilisateurs pour refléter les changements.
    } else {
      console.error('Erreur lors de la sauvegarde de l\'utilisateur');
    }
    setIsDrawerOpen(false);
  };

  const UserFormDrawer = ({ isOpen, onClose, user, onSave, isEdit }) => {
    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "", // Ajout du champ lastName
      email: "",
      password: "",
      phone: "",
      birthDate: "",
      avatar: "", // Ajout du champ avatar
      role: "user", // Valeur par défaut
      isVerified: false,
      ...user
    });

    useEffect(() => {
      setFormData({ ...formData, ...user }); // Réinitialise les données du formulaire lors de l'édition
    }, [user]);

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData(prevState => ({ ...prevState, [name]: type === 'checkbox' ? checked : value }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      onSave(formData);
      onClose();
    };

    return (
      <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
        <Box p={2}>
          <form onSubmit={handleSubmit}>
            <TextField label="Prénom" name="firstName" value={formData.firstName || ''} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Nom" name="lastName" value={formData.lastName || ''} onChange={handleChange} fullWidth margin="normal" /> {/* Ajout du champ lastName */}
            <TextField label="Email" name="email" value={formData.email || ''} onChange={handleChange} fullWidth margin="normal" />
            {isEdit ? null : (
              <TextField label="Mot de passe" type="password" name="password" value={formData.password || ''} onChange={handleChange} fullWidth margin="normal" />
            )}
            <TextField label="Téléphone" name="phone" value={formData.phone || ''} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Date de naissance" InputLabelProps={{ shrink: true }} name="birthDate" value={formData.birthDate || ''} onChange={handleChange} fullWidth margin="normal" />
            <TextField label="Avatar" name="avatar" value={formData.avatar || ''} onChange={handleChange} fullWidth margin="normal" /> {/* Ajout du champ avatar */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Rôle</InputLabel>
              <Select name="role" value={formData.role} onChange={handleChange}>
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox name="isVerified" checked={formData.isVerified} onChange={handleChange} />
              }
              label="Vérifié"
            />
            <Button type="submit" sx={{ mt: 2 }} variant="contained">{isEdit ? "Éditer" : "Ajouter"}</Button>
          </form>
        </Box>
      </Drawer>
    );
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const filteredUsers = selectedTab === 0
    ? users.filter(user => user.isVerified)
    : users.filter(user => !user.isVerified);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 2 }}>
        <Tabs value={selectedTab} onChange={handleTabChange} centered aria-label="Filtre des utilisateurs">
          <Tab label="Vérifiés" />
          <Tab label="Non vérifiés" />
        </Tabs>
      </Box>

      <Box sx={{ margin: 2 }}>
        <Button startIcon={<AddIcon />} onClick={handleAddUser} variant="contained">Ajouter un utilisateur</Button>
      </Box>

      <Grid container spacing={2} sx={{ mb: 4 }}>
        {filteredUsers.map((user) => (
          <Grid item xs={12} sx={{ ml: 2, mr: 2 }} key={user._id}>
            <Card elevation={0} sx={{ pl: 1, pt: 1, pb: 1, borderRadius: '8px', border: '1px solid', borderColor: 'grey.300', overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                <Avatar src={user.avatar || '/path/to/default/avatar.jpg'} sx={{ mr: 2 }} /> {/* Affichage de l'avatar */}
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" component="div">
                    {user.firstName} {user.lastName} {/* Affichage du prénom et du nom */}
                  </Typography>
                  <Typography color="text.secondary" sx={{ fontSize: '0.875rem' }}>
                    {user.email}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                    Rôle: {user.role} <br />
                    Verifié: {user.isVerified ? 'Oui' : 'Non'}
                  </Typography>
                </Box>
                <Box>
                  <IconButton onClick={() => handleEdit(user)} size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(user._id)} size="small">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      <UserFormDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        user={currentUserToEdit}
        onSave={handleSaveUser}
        isEdit={Boolean(currentUserToEdit)}
      />
    </>
  );
};

export default UserManagementComponent;
