import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PlaygroundPage = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState('inactive');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const updateSubscriptionStatus = async (status) => {
    try {
      const response = await fetch(`${backendUrl}/api/users/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          subscriptionStatus: status,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Erreur lors de la mise à jour du statut d\'abonnement.');
      }

      setSubscriptionStatus(status);
      setSuccess('Statut d\'abonnement mis à jour avec succès.');
      setError('');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut d\'abonnement:', error.message);
      setError('Erreur lors de la mise à jour du statut d\'abonnement.');
      setSuccess('');
    }
  };

  const handleNavigateToStatus = () => {
    navigate('/status');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => updateSubscriptionStatus('active')}
        sx={{ mb: 2 }}
      >
        Activer l'abonnement
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => updateSubscriptionStatus('inactive')}
      >
        Désactiver l'abonnement
      </Button>
      <Button
        variant="contained"
        color="info"
        onClick={handleNavigateToStatus}
        sx={{ mt: 3 }}
      >
        Aller à Status
      </Button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}
      <Box mt={3}>
        <p>Statut actuel de l'abonnement : {subscriptionStatus}</p>
      </Box>
    </Box>
  );
};

export default PlaygroundPage;
