// /components/profile/friends/InviteFriends.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { Share } from '@capacitor/share';
import { useAuth } from '../../../contexts/AuthContext';

const InviteFriends = () => {
  const { currentUser } = useAuth();

  const handleShare = async () => {
    try {
      await Share.share({
        title: 'Rejoins-moi sur Raoul!',
        text: `Hey! Rejoins-moi sur Raoul et ajoute-moi en tant qu'ami avec mon nom d'utilisateur: ${currentUser?.username}`,
        url: 'https://heyraoul.fr',
        dialogTitle: 'Partager Raoul',
      });
    } catch (error) {
      console.error('Erreur lors du partage:', error);
    }
  };

  return (
    <Box
      sx={{
        mb: 1.5,
        mt:1.5,
        p: 2,
        backgroundColor: 'secondary.main',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer'
      }}
      onClick={handleShare}
    >
      <IconButton color="inherit" onClick={handleShare}>
        <ShareIcon />
      </IconButton>
      <Box sx={{ flex: 1, ml: 2 }}>
        <Typography variant="body1" sx={{ fontWeight:'bold', mb:0 }} gutterBottom>
          Invite tes amis pour méditer
        </Typography>
        <Typography variant="body2">
          Nom d'utilisateur : <strong>{currentUser?.username}</strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default InviteFriends;
