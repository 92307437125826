import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardActionArea, CardMedia, Grid, Skeleton, useTheme } from '@mui/material';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { useNavigate } from 'react-router-dom';

const MeditationCard = ({ meditation }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card elevation="0" sx={{ display: 'flex', borderRadius: 0, alignItems: 'center', mb: 1, overflow: 'hidden' }}>
      <CardActionArea sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start' }} onClick={() => navigate(`/meditation/${meditation._id}`)}>
        {/* Image à gauche avec image par défaut et couleur de fond */}
        <Box sx={{ width: 74, height: 74, borderRadius: 2, overflow: 'hidden', backgroundColor: theme.palette.secondary.main, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CardMedia
            component="img"
            image={meditation.image || '/img/techniques.svg'}  // Image par défaut
            alt={meditation.title}
            sx={{ objectFit: 'contain' }}
          />
        </Box>
        {/* Contenu texte */}
        <Box sx={{ p: 1.5 }}>
          <Typography variant="body1" fontWeight='bold' sx={{ mb: 0 }}>
            {meditation.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
            <AccessTimeFilledRoundedIcon sx={{ fontSize: 16, mr: 0.5 }} />
            {meditation.duration} min
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const TopMeditations = () => {
  const [meditations, setMeditations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeditations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/meditations`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          // Trier les méditations par playCount et en prendre 2
          const topTwoMeditations = data.sort((a, b) => b.playCount - a.playCount).slice(0, 2);
          setMeditations(topTwoMeditations);
        } else {
          console.error('Erreur lors de la récupération des méditations.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des méditations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMeditations();
  }, []);

  if (loading) {
    return (
      <Box sx={{ flexGrow: 1, mb: 3 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Méditations populaires
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            {[1, 2].map((item) => (
              <Card key={item} elevation="0" sx={{ display: 'flex', borderRadius: 0, alignItems: 'center', mb: 1, overflow: 'hidden' }}>
                <Skeleton variant="rectangular" width={74} height={74} sx={{ borderRadius: 2 }} />
                <Box sx={{ p: 1.5 }}>
                  <Skeleton variant="text" width={100} height={24} />
                  <Skeleton variant="text" width={50} height={16} sx={{ mt: 1 }} />
                </Box>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        Méditations populaires
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          {meditations.map((meditation) => (
            <MeditationCard key={meditation._id} meditation={meditation} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopMeditations;
