import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import AdminNavBarComponent from '../../components/admin/AdminNavBarComponent'; // Ajustez le chemin selon votre projet

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AdminNotifications = () => {
  const [userId, setUserId] = useState('');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [url, setUrl] = useState(''); // Ajout d'un état pour l'URL
  const [response, setResponse] = useState('');

  const handleSendNotification = async () => {
    try {
      const res = await fetch(`${backendUrl}/api/notifications/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Assurez-vous que le token JWT est stocké dans localStorage
        },
        body: JSON.stringify({ userId, title, body, url }), // Inclure l'URL dans la requête
      });

      const text = await res.text();
      try {
        const data = JSON.parse(text);
        if (res.ok) {
          setResponse('Notification sent successfully');
        } else {
          setResponse(`Error: ${data.message}`);
        }
      } catch (error) {
        console.error('Error parsing JSON response:', error);
        console.log('Response text:', text);
        setResponse('Unexpected response from the server');
      }
    } catch (error) {
      setResponse(`Error: ${error.message}`);
    }
  };

  return (
    <Box>
      <AdminNavBarComponent />
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>Send Notification</Typography>
        <TextField
          fullWidth
          label="User ID"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Body"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)} // Nouveau champ pour l'URL
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleSendNotification}>Send Notification</Button>
        {response && <Typography variant="body1" sx={{ mt: 2 }}>{response}</Typography>}
      </Box>
    </Box>
  );
};

export default AdminNotifications;
