import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MeditationCardComponent from './MeditationCardComponent';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ProgramListComponent = () => {
  const navigate = useNavigate();
  const [programs, setPrograms] = useState([]); // État pour stocker les programmes

  useEffect(() => {
    // Fonction pour charger les programmes publics
    const fetchPrograms = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/programs/public`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('La récupération des programmes publics a échoué');
        }
        const data = await response.json();
        setPrograms(data); // Mettre à jour l'état avec les programmes récupérés
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchPrograms();
  }, []); // Le tableau de dépendances vide assure que l'effet s'exécute une fois au montage

  // Fonction pour gérer le clic sur une carte
  const handleCardClick = (id) => {
    navigate(`/program/${id}`); // Redirection vers une page de détail de programme
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
      {programs.map((program) => (
        <MeditationCardComponent
          key={program._id} // Ajusté pour utiliser _id de MongoDB
          image={program.image || ''} // Utiliser une image par défaut si aucune image n'est fournie
          title={program.title}
          category={program.category}
          duration={program.daysCount}
          description={program.description}
          isProgramList={true}
          onClick={() => handleCardClick(program._id)} // Passer _id à handleCardClick
        />
      ))}
    </Box>
  );
};

export default ProgramListComponent;
