import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

// Création du contexte
const SocketContext = createContext();

// Hook pour faciliter l'utilisation du contexte
export const useSocket = () => useContext(SocketContext);

// Composant provider pour gérer la connexion socket
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Initialisation de la connexion socket à l'URL du backend
    const newSocket = io(process.env.REACT_APP_BACKEND_URL, {
      withCredentials: true,
    });

    setSocket(newSocket);

    // Déconnecter le socket lorsque le composant est démonté
    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
