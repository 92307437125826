import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

const CancelPage = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate('/checkout');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', textAlign: 'center', padding: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Abonnement annulé
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Votre processus d'abonnement a été annulé. Vous pouvez réessayer.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleRetry}>
        Réessayer le paiement
      </Button>
    </Box>
  );
};

export default CancelPage;
