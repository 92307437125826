import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; 
import { io } from 'socket.io-client'; 
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; // Pour les pastilles rouges/vertes

const avatarBasePath = '/avatar/';

const ActiveUsers = () => {
  const { roomCode } = useParams();
  const { currentUser } = useAuth();  // Obtenir les détails du user et ses amis
  const [roomUsers, setRoomUsers] = useState([]);
  const [readyUsers, setReadyUsers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [friends, setFriends] = useState([]); // Liste des amis pour récupérer les détails

  // Fonction pour récupérer les détails d'un utilisateur
  const getUserDetails = (userId) => {
    const friend = friends.find(friend => friend._id === userId) || {};
    const friendAvatar = friend.avatar && !friend.avatar.startsWith(avatarBasePath)
      ? `${avatarBasePath}${friend.avatar.replace('./avatar/', '')}`
      : friend.avatar ? friend.avatar : '/default-avatar.png'; // Avatar par défaut si non trouvé
    return { ...friend, avatar: friendAvatar };
  };

  // Récupérer les utilisateurs de la room et leurs informations
  useEffect(() => {
    const fetchRoomUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/${roomCode}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });
        const roomData = await response.json();
        setRoomUsers(roomData.userIds);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs de la room:', error);
      }
    };

    const fetchFriends = async () => {
      if (currentUser && currentUser.friends) {
        setFriends(currentUser.friends);
      }
    };

    fetchRoomUsers();
    fetchFriends();
  }, [roomCode, currentUser]);

  // Mise à jour des utilisateurs en ligne et prêts via le socket
  useEffect(() => {
    const socket = io(process.env.REACT_APP_BACKEND_URL);

    socket.emit('joinRoom', { room: roomCode, token: localStorage.getItem('token') });

    // Réception de la liste des utilisateurs en ligne
    socket.on('updateUserList', (userList) => {
      setOnlineUsers(userList.filter(user => !user.disconnected).map(user => user.userId));
    });

    // Réception de la liste des utilisateurs prêts
    socket.on('updateReadyUsers', (readyUserList) => {
      setReadyUsers(readyUserList.map(user => user.userId));
    });

    return () => {
      socket.disconnect();
    };
  }, [roomCode]);

  // Trier les utilisateurs pour afficher les en ligne en premier
  const sortedUsers = roomUsers.sort((a, b) => {
    const isAOnline = onlineUsers.includes(a);
    const isBOnline = onlineUsers.includes(b);

    if (isAOnline && !isBOnline) return -1;
    if (!isAOnline && isBOnline) return 1;
    return 0; // Laisser l'ordre inchangé si les deux sont en ligne ou hors ligne
  });

  // Affichage des utilisateurs avec leurs détails et statut (en ligne, hors ligne, prêt)
  return (
    <Box
      sx={{
        overflowX: 'auto',   // Permet le défilement horizontal
        overflowY: 'hidden', // Masque la barre de défilement verticale
        whiteSpace: 'nowrap', // Évite le retour à la ligne
        '&::-webkit-scrollbar': {
          display: 'none' // Cache la barre de défilement pour Webkit (Chrome, Safari)
        }
      }}
    >
      <Box sx={{ display: 'inline-flex', p: 1 }}>
        {sortedUsers.map(userId => {
          const isReady = readyUsers.includes(userId);  // Vérifie si l'utilisateur est prêt
          const isOnline = onlineUsers.includes(userId);  // Vérifie si l'utilisateur est en ligne

          // Si l'utilisateur est le currentUser, afficher ses informations
          const userDetails = userId === currentUser.userId
            ? {
                firstName: currentUser.firstName || 'Inconnu',
                lastName: currentUser.lastName,
                avatar: currentUser.avatar 
                  ? currentUser.avatar.startsWith(avatarBasePath) || currentUser.avatar.startsWith('http')
                    ? currentUser.avatar  // Utiliser l'avatar directement s'il commence par avatarBasePath ou http
                    : `${avatarBasePath}${currentUser.avatar.replace('./avatar/', '')}`  // Sinon, ajouter le chemin de base et enlever './avatar/'
                  : '/default-avatar.png'  // Avatar par défaut si aucune image
              }
            : getUserDetails(userId); // Sinon, récupérer les détails de l'ami

          return (
            <Box key={userId} sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', pt:1, pb:0.5, px: 1 }}>
              <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Avatar src={userDetails.avatar} sx={{ width: 40, height: 40, border: '2px solid black' }} />
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={
                    isReady ? (
                      <Box sx={{ bgcolor: 'white', borderRadius: '50%', width: 14, height: 14, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CheckCircleIcon color="primary" sx={{ width: 18, height: 18 }} />
                      </Box>
                    ) : isOnline ? (
                      <FiberManualRecordIcon sx={{ width: 18, height: 18, color: 'green' }} />
                    ) : (
                      <FiberManualRecordIcon sx={{ width: 18, height: 18, color: 'red' }} />
                    )
                  }
                  sx={{ position: 'absolute', top: '4px', right: '4px' }} // Ajustement fin de la position du badge
                />
              </Box>
              <Typography variant="body2" sx={{ textAlign: 'center', mt: 0.5, fontWeight: 'bold', fontSize:'0.8em' }}>
                {userDetails.firstName || 'Inconnu'}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ActiveUsers;
