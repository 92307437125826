import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import MessagesList from './MessagesList';
import MessageInput from './MessageInput';
import InviteCard from './InviteCard';  // Importation de InviteCard
import useDeviceInfo from '../../utils/useDeviceInfo';
import ZenSpinner from '../../components/ZenSpinner';  // Importation du spinner
import { useSocket } from '../../contexts/SocketContext'; // Utilisation du SocketContext

const Chat = ({ roomCode }) => {
  const { currentUser } = useAuth();
  const socket = useSocket();  // Récupération du socket depuis le context
  const [messages, setMessages] = useState([]);
  const [connectedUsers, setConnectedUsers] = useState({});
  const [isInviteCardVisible, setIsInviteCardVisible] = useState(false); // État pour suivre l'affichage de l'InviteCard
  const inviteMessageRef = useRef(null); 
  const messagesEndRef = useRef(null);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const [isLoading, setIsLoading] = useState(true); // État pour le spinner de chargement

  const joinRoom = useCallback(() => {
    if (socket) {
      socket.emit('joinRoom', { room: roomCode, token: localStorage.getItem('token') });
    }
  }, [roomCode, socket]);

  useEffect(() => {
    if (socket && currentUser) {
      joinRoom();  // Rejoindre la salle lorsque le socket et l'utilisateur sont prêts

      socket.on('updateUserList', (users) => {
        const usersById = {};
        users.forEach(user => {
          usersById[user.userId] = {
            name: user.name,
            profileImage: user.profileImage,
          };
        });
        setConnectedUsers(usersById);
      });

      socket.on('receiveMessage', (msg) => {
        setMessages(prevMessages => [...prevMessages, msg]);
      });

      socket.on('roomHistory', (history) => {
        setMessages(history);
      });

      return () => {
        socket.off('updateUserList');
        socket.off('receiveMessage');
        socket.off('roomHistory');
      };
    }
  }, [socket, currentUser, joinRoom]);

  useEffect(() => {
    // Afficher le spinner pendant 3 secondes puis cacher
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 600);

    return () => clearTimeout(timer); // Nettoyage du timeout
  }, []);

  useEffect(() => {
    if (!isLoading) {
      scrollToBottom();
    }
  }, [messages, isLoading]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: paddingTop + 8, pb: paddingBottom + 1 }}>
      {isLoading && (
        <Box 
          sx={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            width: '100%', 
            height: '100%', 
            backgroundColor: 'rgba(255, 255, 255, 1)',  // légèrement transparent
            zIndex: 1000, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}
        >
          <ZenSpinner /> {/* Affichage du spinner */}
        </Box>
      )}
      {/* Ajouter InviteCard comme bannière persistante en haut */}
      <Box sx={{ position: 'sticky', top: 66, width:'100%', zIndex: 1 }}>
        <InviteCard roomCode={roomCode} activeUsers={connectedUsers} onShow={() => setIsInviteCardVisible(true)} onHide={() => setIsInviteCardVisible(false)} />
      </Box>
      <MessagesList 
        messages={messages}
        currentUser={currentUser}
        connectedUsers={connectedUsers}
        inviteMessageRef={inviteMessageRef}
        messagesEndRef={messagesEndRef}
        roomCode={roomCode}
        isInviteCardVisible={isInviteCardVisible}  // Passer l'état de l'InviteCard
      />
      <MessageInput
        roomCode={roomCode}
        socket={socket} // Passer le socket via le contexte
        messages={messages}
        currentUser={currentUser}
        inviteMessageRef={inviteMessageRef}
      />
    </Box>
  );
};

export default Chat;
