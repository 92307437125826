import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Box } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; 
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { useTheme } from '@mui/material/styles';

const MeditationCardComponent = ({ image, title, category, duration, description, onClick, showIcon = true, isProgramList = false }) => {
  const theme = useTheme();

  const CardMediaContent = image ? (
    <CardMedia
      component="img"
      height="140"
      image={image} 
      sx={{ borderRadius: '6px', mb: 1.5, bgcolor:'secondary.main' }}
    />
  ) : (
    <Box
      sx={{
        height: '140px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '6px',
        mb: 1,
      }}
    >
      {/* Si pas d'image, tu pourrais afficher une icône ou un placeholder ici */}
    </Box>
  );

  return (
    <Card sx={{ maxWidth: 345, boxShadow: 'none', border: 0, bgcolor: "transparent", mb:0.5 }}>
      <CardActionArea onClick={onClick} sx={{ p: 0, "&:hover": { bgcolor: "transparent" } }}>
        {CardMediaContent}
        <CardContent sx={{ p: 0 }}>
            <Typography gutterBottom variant="body1" sx={{ fontWeight: 'bold', mt: 0.2, mb: 0.2 }}>
                {title}
            </Typography>
            <Typography variant="body3" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
              <AccessTimeFilledRoundedIcon sx={{ fontSize: 14, mr:0.5 }} /> {duration} min
            </Typography>
            <Typography variant="body3" color="textSecondary" sx={{ mt: 1 }}>
             {description}
            </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MeditationCardComponent;