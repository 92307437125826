import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import ZenSpinner from '../components/ZenSpinner';

const SubscriberRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();

  if (isLoading) {
    return <ZenSpinner />;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (currentUser.subscriptionStatus !== 'active' && currentUser.subscriptionStatus !== 'trialing') {
    return <Navigate to="/checkout" />;
  }

  return children;
};

export default SubscriberRoute;
