import React, { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { useNavigate, useLocation } from 'react-router-dom';
import useDeviceInfo from '../utils/useDeviceInfo';  

const NavBarComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(getIndexFromPathname(location.pathname));
  const { paddingTop, paddingBottom } = useDeviceInfo();

  useEffect(() => {
    setValue(getIndexFromPathname(location.pathname));
  }, [location]);

  function getIndexFromPathname(pathname) {
    switch (pathname) {
      case '/today':
        return 1;
      case '/feed':
        return 0;
      case '/temple':
        return 2;
      default:
        return 1;
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 1:
        navigate('/today');
        break;
      case 0:
        navigate('/feed');
        break;
      case 2:
        navigate('/temple');
        break;
      default:
        break;
    }
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, borderTop: '1px solid #DDDDDD', borderRadius: 0, zIndex: 1000, paddingBottom: paddingBottom }}>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        sx={{ height: 65 }}
      >
        <BottomNavigationAction
          icon={<PeopleAltRoundedIcon />}
          sx={{ color: value === 0 ? 'primary.main' : 'text.disabled' }} 
        />
        <BottomNavigationAction
          icon={<HomeRoundedIcon />}
          sx={{ color: value === 1 ? 'primary.main' : 'text.disabled' }} 
        />
        <BottomNavigationAction
          icon={<WidgetsRoundedIcon />}
          sx={{ color: value === 2 ? 'primary.main' : 'text.disabled' }} 
        />
      </BottomNavigation>
    </Paper>
  );
};

export default NavBarComponent;
