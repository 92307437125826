import React from 'react';
import { Box, Typography, Container, Button, TextField } from '@mui/material';

const DeleteAccountPage = () => {
  const handleDeleteRequest = () => {
    window.location.href = "mailto:contact@heyraoul.fr?subject=Demande de suppression de compte&body=Bonjour,%0D%0A%0D%0AJe souhaite supprimer mon compte Raoul.%0D%0A%0D%0AMerci.";
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Suppression de Compte
        </Typography>
        <Typography variant="body1" paragraph>
          Si vous souhaitez supprimer votre compte Raoul, veuillez suivre les étapes ci-dessous.
        </Typography>
        <Typography variant="body1" paragraph>
          Pour demander la suppression de votre compte, veuillez envoyer un e-mail à l'adresse suivante :
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>contact@heyraoul.fr</strong>
        </Typography>
        <Typography variant="body1" paragraph>
          Veuillez indiquer dans votre e-mail que vous souhaitez supprimer votre compte. Notre équipe traitera votre demande dans les plus brefs délais.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Types de données supprimées :</strong>
        </Typography>
        <ul>
          <li>Données personnelles (adresse e-mail, prénom, nom, numéro de téléphone, adresse)</li>
          <li>Données d'utilisation</li>
          <li>Données de localisation</li>
        </ul>
        <Typography variant="body1" paragraph>
          <strong>Durée de conservation :</strong> Vos données seront conservées pendant une période de 30 jours après la suppression de votre compte pour des raisons de sécurité et de conformité légale.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Button variant="contained" color="primary" onClick={handleDeleteRequest}>
            Envoyer une demande de suppression
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default DeleteAccountPage;
