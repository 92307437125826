import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Règles de Confidentialité
        </Typography>
        <Typography variant="body1" paragraph>
          Dernière mise à jour : 10/06/2024
        </Typography>
        <Typography variant="body1" paragraph>
          Raoul ("nous", "notre" ou "nos") exploite l'application mobile Raoul (ci-après dénommée le "Service").
        </Typography>
        <Typography variant="body1" paragraph>
          Cette page vous informe de nos politiques concernant la collecte, l'utilisation et la divulgation des données personnelles lorsque vous utilisez notre Service et des choix que vous avez associés à ces données.
        </Typography>
        <Typography variant="body1" paragraph>
          Nous n'utiliserons ni ne partagerons vos informations avec quiconque, sauf comme décrit dans cette Politique de confidentialité.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Collecte et Utilisation des Informations
        </Typography>
        <Typography variant="body1" paragraph>
          Nous collectons plusieurs types d'informations dans le but de fournir et d'améliorer notre Service pour vous.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Types de Données Collectées
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Données Personnelles :</strong> Lorsque vous utilisez notre Service, nous pouvons vous demander de nous fournir certaines informations personnelles identifiables qui peuvent être utilisées pour vous contacter ou vous identifier ("Données Personnelles"). Les informations personnellement identifiables peuvent inclure, mais sans s'y limiter :
        </Typography>
        <ul>
          <li>Adresse e-mail</li>
          <li>Prénom et nom</li>
          <li>Numéro de téléphone</li>
          <li>Adresse, État, Province, Code postal, Ville</li>
        </ul>

        <Typography variant="body1" paragraph>
          <strong>Données d'Utilisation :</strong> Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez notre Service ou lorsque vous accédez au Service par ou via un appareil mobile ("Données d'Utilisation").
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Données de Localisation :</strong> Nous pouvons utiliser et stocker des informations sur votre position si vous nous donnez la permission de le faire ("Données de Localisation"). Nous utilisons ces données pour fournir des fonctionnalités de notre Service, pour améliorer et personnaliser notre Service.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Utilisation des Données
        </Typography>
        <Typography variant="body1" paragraph>
          Raoul utilise les données collectées à des fins diverses :
        </Typography>
        <ul>
          <li>Pour fournir et maintenir notre Service</li>
          <li>Pour vous notifier des changements apportés à notre Service</li>
          <li>Pour vous permettre de participer aux fonctionnalités interactives de notre Service lorsque vous choisissez de le faire</li>
          <li>Pour fournir un support client</li>
          <li>Pour recueillir des analyses ou des informations précieuses afin que nous puissions améliorer notre Service</li>
          <li>Pour surveiller l'utilisation de notre Service</li>
          <li>Pour détecter, prévenir et résoudre les problèmes techniques</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Divulgation des Données
        </Typography>
        <Typography variant="body1" paragraph>
          Nous pouvons divulguer vos données personnelles dans la bonne foi, croyant que cette action est nécessaire pour :
        </Typography>
        <ul>
          <li>Se conformer à une obligation légale</li>
          <li>Protéger et défendre les droits ou la propriété de Raoul</li>
          <li>Prévenir ou enquêter sur de possibles actes répréhensibles en relation avec le Service</li>
          <li>Protéger la sécurité personnelle des utilisateurs du Service ou du public</li>
          <li>Se protéger contre la responsabilité légale</li>
        </ul>

        <Typography variant="h5" gutterBottom>
          Sécurité des Données
        </Typography>
        <Typography variant="body1" paragraph>
          La sécurité de vos données est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet, ou méthode de stockage électronique n'est sécurisée à 100%. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur sécurité absolue.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicyPage;
