import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Switch } from '@mui/material';

const Reminder = ({ roomId, userId, onClose }) => {
  const [reminderTime, setReminderTime] = useState('');
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    // Fonction pour récupérer le rappel actuel de l'utilisateur
    const fetchReminder = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/${roomId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assurez-vous que le token est bien envoyé
          },
        });

        if (!response.ok) {
          console.error('Erreur lors de la récupération du rappel:', response.status);
          return;
        }

        const data = await response.json();
        const userReminder = data.reminders?.find(reminder => reminder.userId === userId);

        if (userReminder) {
          setReminderTime(userReminder.reminderTime);
          setIsEnabled(!!userReminder.reminderTime);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du rappel:', error);
      }
    };

    fetchReminder();
  }, [roomId, userId]);

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    setReminderTime(selectedTime);
    updateReminder(selectedTime);
  };

  const handleToggleChange = (event) => {
    const enabled = event.target.checked;
    setIsEnabled(enabled);
    updateReminder(enabled ? reminderTime : null);
  };

  const updateReminder = async (time) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/rooms/updateUserReminder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assurez-vous que le token est bien envoyé
        },
        body: JSON.stringify({
          roomId,
          userId,
          reminderTime: time,
        }),
      });
    } catch (error) {
      console.error('Erreur lors de la mise à jour du rappel:', error);
    }
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 6; hour < 24; hour++) {
      const timeString = `${hour.toString().padStart(2, '0')}:00`;
      options.push(timeString);
    }
    return options;
  };

  return (
    <Box sx={{ px:1, pt:1, pb:2 }}>
      <Typography variant="body1" fontWeight="bold" sx={{ mb: 2 }}>
        Programmer un rendez-vous
      </Typography>
      <FormControl component="fieldset" fullWidth sx={{ mb: 2 }}>
        <InputLabel id="reminder-time-label">Choisir l'heure</InputLabel>
        <Select
          labelId="reminder-time-label"
          value={reminderTime}
          onChange={handleTimeChange}
          label="Choisir l'heure"
          fullWidth
          disabled={!isEnabled}
        >
          {generateTimeOptions().map((timeOption) => (
            <MenuItem key={timeOption} value={timeOption}>
              {timeOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography variant="body2">Activer le rappel</Typography>
      <Switch checked={isEnabled} onChange={handleToggleChange} />
    </Box>
  );
};

export default Reminder;
