import React, { useState, useEffect } from 'react';
import {
  Button, Card, CardContent, Drawer, IconButton, Typography,
  Grid, Box, TextField
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const QuoteManagementComponent = () => {
  const [quotes, setQuotes] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentQuoteToEdit, setCurrentQuoteToEdit] = useState(null);

  useEffect(() => {
    fetchQuotes();
  }, []);

  const fetchQuotes = async () => {
    const response = await fetch(`${backendUrl}/api/quotes`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setQuotes(data);
    } else {
      console.error('Erreur lors de la récupération des citations');
    }
  };

  const handleEdit = (quote) => {
    setCurrentQuoteToEdit(quote);
    setIsDrawerOpen(true);
  };

  const handleDelete = async (quoteId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette citation ?')) {
      const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/api/quotes/${quoteId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setQuotes(quotes.filter(quote => quote._id !== quoteId));
      } else {
        console.error('Erreur lors de la suppression de la citation');
      }
    }
  };

  const handleAddQuote = () => {
    setCurrentQuoteToEdit(null);
    setIsDrawerOpen(true);
  };

  const handleSaveQuote = async (formData) => {
    const token = localStorage.getItem('token');
    let url = `${backendUrl}/api/quotes`;
    let method = 'POST';
    if (formData._id) {
      url += `/${formData._id}`;
      method = 'PUT';
    }
    const body = JSON.stringify(formData);

    const response = await fetch(url, {
      method,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.ok) {
      fetchQuotes(); // Re-fetch the list to reflect changes
      setIsDrawerOpen(false);
    } else {
      console.error('Erreur lors de la sauvegarde de la citation');
    }
  };

  const QuoteFormDrawer = ({ isOpen, onClose, quote }) => {
    const [formData, setFormData] = useState({
      content: '',
      author: '',
      source: '',
      title: '',
      ...quote,
    });

    useEffect(() => {
      if (quote) {
        setFormData(quote);
      }
    }, [quote]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    };

    const handleSourceChange = (value) => {
      setFormData(prevState => ({
        ...prevState,
        source: value,
      }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      handleSaveQuote(formData);
      onClose();
    };

    return (
      <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
        <Box p={2} component="form" onSubmit={handleSubmit}>
          <TextField
            label="Contenu"
            name="content"
            value={formData.content || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Auteur"
            name="author"
            value={formData.author || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Titre"
            name="title"
            value={formData.title || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" gutterBottom>Source</Typography>
          <ReactQuill
            value={formData.source || ''}
            onChange={handleSourceChange}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, 
                 {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image'],
                ['clean']
              ],
            }}
          />
          <Button type="submit" sx={{ mt: 2 }} variant="contained">
            Sauvegarder
          </Button>
        </Box>
      </Drawer>
    );
  };

  return (
    <>
      <Box sx={{ margin: 2 }}>
        <Button startIcon={<AddIcon />} onClick={handleAddQuote} variant="contained">
          Ajouter une citation
        </Button>
      </Box>
      <Box sx={{ ml: 2, mr: 2, mb: 4 }}>
        <Grid container spacing={2}>
          {quotes.map(quote => (
            <Grid item xs={12} sm={6} md={4} key={quote._id}>
              <Card elevation={0} sx={{ pl: 1, pt: 1, borderRadius: '8px', border: '1px solid', borderColor: 'grey.300', overflow: 'hidden' }}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {`"${quote.content}"`}
                  </Typography>
                  <Box>
                    <IconButton onClick={() => handleEdit(quote)} size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(quote._id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {isDrawerOpen && (
        <QuoteFormDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          quote={currentQuoteToEdit}
        />
      )}
    </>
  );
};

export default QuoteManagementComponent;
