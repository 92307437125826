import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';

const Badge = ({ onNext, newlyUnlockedBadges }) => {

  return (
    <Box sx={{ backgroundColor: 'secondary.main', height: '100vh' }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        height="90vh"
        sx={{ px: 3 }} 
      >
    {newlyUnlockedBadges.length > 0 ? (
      newlyUnlockedBadges.map((badge, index) => (
        <Box key={badge._id} textAlign="center" mb={2}>
          <Box component="img" src={badge.imageUrl || '/img/badges/default-badge.svg'} alt={badge.title} width="50vw" />
          <Typography variant="body1" textTransform="uppercase" color="primary" mb={1} mt={3}>
            Badge débloqué
          </Typography>
          <Typography variant="h5" color="primary">
            {badge.description}
          </Typography>
        </Box>
      ))
    ) : (
      <Typography variant="h6" color="primary">
        Aucun nouveau badge débloqué.
      </Typography>
    )}
      </Box>
    </Box>
  );
};

export default Badge;
