import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';

// Animation de pulsation
const pulse = keyframes`
  0%, 100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
`;

const ZenSpinner = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      animation: `${pulse} 2s infinite ease-in-out`,
    }}
  >
    <CircularProgress size={60} />
  </Box>
);

export default ZenSpinner;
