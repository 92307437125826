import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Button, Typography, IconButton, LinearProgress, Fade, TextField } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { useAuth } from '../../contexts/AuthContext';
import tiboImage from '../../assets/tibo.png';
import './TalkWithMe.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;


const questions = [
  { question: "Comment tu te sens ?" },
  { question: "À quel point es-tu stressé(e) ?" },
  { question: "Comment évalues-tu ton niveau d'énergie ?" },
  { question: "À quel point te sens-tu préoccupé(e) ?" },
  { question: "À quel point es-tu heureux/se ?" }
];

const icons = [
  { icon: <SentimentVeryDissatisfiedIcon />, label: 'Très triste' },
  { icon: <SentimentDissatisfiedIcon />, label: 'Triste' },
  { icon: <SentimentSatisfiedIcon />, label: 'Satisfait' },
  { icon: <SentimentVerySatisfiedIcon />, label: 'Très heureux' }
];

const TalkWithMe = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState(Array(questions.length).fill(''));
  const [additionalComment, setAdditionalComment] = useState('');

  const handleAnswer = (answer) => {
    const newAnswers = [...answers];
    newAnswers[currentStep - 1] = answer;
    setAnswers(newAnswers);
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleProfile = () => {
    navigate('/today'); // Navigate back to the previous page
  };

  const handleSubmit = async () => {
    const emailContent = `
      <h2>Résumé de la discussion</h2>
      <p><strong>Prénom :</strong> ${currentUser.firstName}</p>
      <p><strong>Email :</strong> ${currentUser.email}</p>
      ${questions.map((q, index) => `<p><strong>${q.question}</strong><br />${answers[index]}</p>`).join('')}
      <p><strong>Commentaire additionnel :</strong><br />${additionalComment}</p>
    `;
    try {
      const response = await fetch(`${backendUrl}/api/email/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          subject: 'Résumé de la discussion',
          html: emailContent
        })
      });

      if (response.ok) {
        setCurrentStep(currentStep + 1);
      } else {
        const errorData = await response.json();
        alert('Erreur lors de l\'envoi de l\'email. Veuillez réessayer.');
        console.error('Error:', errorData);
      }
    } catch (error) {
      alert('Erreur lors de l\'envoi de l\'email. Veuillez réessayer.');
      console.error('Error:', error);
    }
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', p: 3 }}>
        <Fade in timeout={300}>
          <Box>
            {currentStep === 0 && (
              <>
                <img src={tiboImage} alt="Tibo" width="100px" style={{ borderRadius: '50%', border:'3px solid black' }} />
                <Typography variant="h4" sx={{ mb: 3, mt:1 }}>
                  Hey ! J'ai quelques questions pour toi
                </Typography>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  Commencer
                </Button>
                <Button variant="text" color="primary" onClick={handleProfile}>
                  Annuler
                </Button>
              </>
            )}
            {currentStep > 0 && currentStep <= questions.length && (
              <>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {questions[currentStep - 1].question}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  {icons.map((iconItem, index) => (
                    <IconButton
                      key={index}
                      onClick={() => handleAnswer(iconItem.label)}
                      sx={{
                        mx: 1,
                        border: answers[currentStep - 1] === iconItem.label ? '2px solid black' : '2px solid white'
                      }}
                    >
                      {iconItem.icon}
                    </IconButton>
                  ))}
                </Box>
              </>
            )}
            {currentStep === questions.length + 1 && (
              <>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  As-tu un mot à ajouter ?
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  value={additionalComment}
                  onChange={(e) => setAdditionalComment(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Envoyer
                </Button>
              </>
            )}
            {currentStep === questions.length + 2 && (
              <>
                <img src={tiboImage} alt="Tibo" style={{ width: '80px', borderRadius: '50%', marginBottom: '10px', border:'3px solid black'  }} />
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Merci, {currentUser.firstName}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Je te répondrai dans les prochains jours.
                </Typography>
                <Button variant="contained" aria-label="retour" onClick={handleProfile} >
                Terminer
                </Button>
              </>
            )}
          </Box>
        </Fade>
      </Box>
      {currentStep > 0 && currentStep <= questions.length && (
        <Box sx={{ position: 'fixed', bottom: 10, width: '100%', p: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="grey" onClick={handlePrev} sx={{ mr:1 }}>
            Précédent
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            {currentStep < questions.length ? 'Suivant' : 'Terminer'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TalkWithMe;
