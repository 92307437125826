import React, { useState, useEffect } from 'react';
import { Box, Drawer, Button, TextField, Typography, Grid, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import useDeviceInfo from '../../utils/useDeviceInfo';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const avatarBasePath = './avatar/';
const avatars = [
  '001.jpg',
  '002.jpg',
  '003.jpg',
  '004.jpg',
  '005.jpg',
  '006.jpg',
];

const EditProfile = ({ isOpen, onClose, onUpdate }) => {
  const { currentUser, logout, refreshUser } = useAuth();
  const [profileData, setProfileData] = useState({
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    email: currentUser?.email || '',
    avatar: currentUser?.avatar || '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleAvatarSelect = (avatar) => {
    setProfileData({ ...profileData, avatar: `${avatarBasePath}${avatar}` });
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return password.length >= minLength && hasUpperCase && hasNumber && hasSpecialChar;
  };

  const handleUpdateProfile = async () => {
    if (profileData.password && !validatePassword(profileData.password)) {
      setError("Le mot de passe doit contenir au moins une majuscule, un chiffre, un caractère spécial, et être d'au moins 8 caractères.");
      return;
    }

    if (profileData.password !== profileData.confirmPassword) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/api/users/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(profileData),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Erreur lors de la mise à jour du profil');
      }

      await refreshUser();
      onClose();
      onUpdate();
      setSuccess('Profil mis à jour avec succès.');
      setError('');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error.message);
      setError('Erreur lors de la mise à jour du profil.');
      setSuccess('');
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmation !== 'aurevoir') {
      setError('Vous devez taper "aurevoir" pour confirmer la suppression de votre compte.');
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/api/users/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression du compte');
      }

      logout();
      onClose();
    } catch (error) {
      console.error('Erreur lors de la suppression du compte:', error.message);
      setError('Erreur lors de la suppression du compte.');
    }
  };

  return (
    <>
      <Drawer anchor="right" open={isOpen} onClose={onClose}>
        <Box sx={{ width: 300, p: 3, pt: paddingTop + 3.5 }}>
          <Typography variant="h6" sx={{ mb: 1.5 }} gutterBottom>Mon profil</Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {avatars.map((avatar) => (
              <Grid item xs={4} key={avatar}>
                <img
                  src={`${avatarBasePath}${avatar}`}
                  alt={avatar}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    border: profileData.avatar === `${avatarBasePath}${avatar}` ? '6px solid #000' : '3px solid #000'
                  }}
                  onClick={() => handleAvatarSelect(avatar)}
                />
              </Grid>
            ))}
          </Grid>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
          <TextField
            label="Prénom"
            name="firstName"
            value={profileData.firstName}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Nom"
            name="lastName"
            value={profileData.lastName}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            name="email"
            value={profileData.email}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Nouveau mot de passe"
            type="password"
            name="password"
            value={profileData.password}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Confirmer le mot de passe"
            type="password"
            name="confirmPassword"
            value={profileData.confirmPassword}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleUpdateProfile} sx={{ mt: 0.5 }}>
            Sauvegarder
          </Button>
          <Button variant="contained" color="grey" onClick={handleLogout} sx={{ mt: 1 }}>
            Déconnexion
          </Button>
          <Button variant="text" color="error" onClick={() => setDeleteDialogOpen(true)} sx={{ mt: 1 }}>
            Supprimer mon compte
          </Button>
        </Box>
      </Drawer>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pour confirmer la suppression de votre compte, veuillez taper "aurevoir" ci-dessous.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Confirmation"
            fullWidth
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProfile;
