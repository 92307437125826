import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoImage from '../../assets/logo.svg';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const VerifyAccountPage = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(''); // Pour stocker l'email pour renvoyer le lien de vérification
  const [resendMessage, setResendMessage] = useState(''); // Message après la demande de renvoi
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      fetch(`${backendUrl}/api/users/verify?token=${token}`)
        .then(response => response.json())
        .then(data => {
          setLoading(false);
          setMessage(data.message);
        })
        .catch(error => {
          console.error('Error verifying account:', error);
          setLoading(false);
          setMessage('An error occurred during verification.');
        });
    } else {
      setLoading(false);
      setMessage('No verification token provided.');
    }
  }, [searchParams]);

  const handleResendVerificationEmail = () => {
    setLoading(true);
    fetch(`${backendUrl}/api/users/resend-verification-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        setResendMessage(data.message);
      })
      .catch(error => {
        console.error('Error resending verification email:', error);
        setLoading(false);
        setResendMessage('An error occurred while resending the verification email.');
      });
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      p: 4,
    }}>
      <img src={LogoImage} alt="Logo" style={{ height: '30px', margin: '0 auto', marginBottom: '2rem' }} />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h6" sx={{ mb: 0.5 }}>{message}</Typography>
          {message === 'Email vérifié avec succès.' && (
            <>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Vous pouvez maintenant vous connecter dans l'application.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => window.location.href = 'raoulapp://deep-link/login'}
              >
                Ouvrir l'application
              </Button>
            </>
          )}
          {message !== 'Email vérifié avec succès.' && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Si vous n'avez pas reçu l'email de vérification, entrez votre email ci-dessous pour renvoyer le lien.
              </Typography>
              <TextField
                fullWidth
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleResendVerificationEmail}
                disabled={loading}
              >
                Renvoyer le lien de vérification
              </Button>
              {resendMessage && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {resendMessage}
                </Typography>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default VerifyAccountPage;
