import React from 'react';
import { Box, Typography } from '@mui/material';
import AdminNavBarComponent from '../../components/admin/AdminNavBarComponent'; // Ajuste le chemin selon l'organisation de ton projet
import TechniqueManagementComponent from '../../components/admin/TechniqueManagementComponent'; // Ajuste le chemin selon l'organisation de ton projet

const AdminPrograms = () => {
  return (
    <Box>
        <AdminNavBarComponent />
    <TechniqueManagementComponent />
</Box>
  );
};

export default AdminPrograms;
