import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import ZenSpinner from '../components/ZenSpinner';  // Assurez-vous que le chemin est correct

const RedirectPage = () => {
  useEffect(() => {
    window.location.href = "raoulapp://";
  }, []);

  return (
    <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <ZenSpinner />
    </Container>
  );
};

export default RedirectPage;
