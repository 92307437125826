import React from 'react';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import SpaIcon from '@mui/icons-material/Spa';
// Ajoute d'autres importations d'icônes au besoin

const getCategoryIcon = (category, style = {}) => {
  switch (category) {
    case 'Libre':
      return <FreeBreakfastIcon style={style} />;
    case 'Programme':
      return <SpaIcon style={style} />;
    // Ajoute d'autres cas au besoin
    default:
      return <SpaIcon style={style} />; // Une icône par défaut
  }
};

export default getCategoryIcon;
