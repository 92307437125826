import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton, Typography, Slider } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ReplayIcon from '@mui/icons-material/Replay'; // Icone de replay
import { Device } from '@capacitor/device';

const VideoPlayer = ({ videoUrl, open, onClose }) => {
  const videoRef = useRef(null); // Référence vers l'élément vidéo
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [ended, setEnded] = useState(false); // État pour savoir si la vidéo est terminée
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    const checkPlatform = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        setIsIos(true);
      }
    };

    checkPlatform();
  }, []);

  // Play/Pause toggle
  const handlePlayPause = () => {
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlaying(!playing);
  };

  // Mute toggle
  const handleMute = () => {
    videoRef.current.muted = !muted;
    setMuted(!muted);
  };

  // Update progress
  const handleProgress = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    setProgress((currentTime / duration) * 100);
    setDuration(duration);
  };

  // Seek video
  const handleSeek = (event, newValue) => {
    const newTime = (newValue / 100) * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
    setProgress(newValue);
  };

  // Format time in minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  // Gestion du chargement de la vidéo
  const handleVideoLoaded = () => {
    setPlaying(false); // La vidéo est prête mais ne démarre pas encore
  };

  // Gestion de la fin de la vidéo
  const handleVideoEnded = () => {
    setPlaying(false);
    setEnded(true); // Marque que la vidéo est terminée
  };

  // Rejouer la vidéo
  const handleReplay = () => {
    videoRef.current.currentTime = 0; // Remet la vidéo à 0
    videoRef.current.play();
    setProgress(0); // Réinitialiser la barre de progression
    setEnded(false); // Réinitialiser l'état de fin de vidéo
    setPlaying(true);
  };

  useEffect(() => {
    if (open) {
      setPlaying(true); // Start playing the video when the popin opens
      videoRef.current.play(); // Trigger autoplay
      setEnded(false); // Reset video end state
    } else {
      videoRef.current.pause(); // Pause video if the dialog is closed
      setPlaying(false);
    }
  }, [open]);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: 'black',
        display: open ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300,
      }}
    >
      <video
        ref={videoRef}
        src={videoUrl}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover', // Toujours plein écran sans bandes noires
        }}
        onTimeUpdate={handleProgress}
        onLoadedData={handleVideoLoaded} // Gestion du chargement de la vidéo
        onEnded={handleVideoEnded} // Gestion de la fin de la vidéo
      />

      <IconButton
        sx={{
          position: 'absolute',
          top: isIos ? 40 : 20,
          left: 20,
          color: 'black',
          bgcolor: 'white'
        }}
        onClick={onClose}
      >
        <FullscreenExitIcon />
      </IconButton>

      {!ended && (
        <Box
          sx={{
            position: 'absolute',
            bottom: isIos ? 30 : 20,
            left: 20,
            right: 20,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            px: 2,
          }}
        >
          <IconButton onClick={handlePlayPause} sx={{ color: 'white' }}>
            {playing ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>

          <Box sx={{ flexGrow: 1, mx: 2 }}>
            <Slider
              value={progress}
              onChange={handleSeek}
              aria-labelledby="video-progress-slider"
              sx={{ color: 'white' }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: -1 }}>
              <Typography variant="body2" color="white">
                {formatTime(videoRef.current?.currentTime || 0)}
              </Typography>
              <Typography variant="body2" color="white">
                {formatTime(duration)}
              </Typography>
            </Box>
          </Box>

          <IconButton onClick={handleMute} sx={{ color: 'white' }}>
            {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
        </Box>
      )}

      {ended && (
        <IconButton
          onClick={handleReplay}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            color: 'black',
            width: 55,
            height: 55,
            borderRadius: '50%',
          }}
        >
          <ReplayIcon sx={{ fontSize: 30 }} />
        </IconButton>
      )}
    </Box>
  );
};

export default VideoPlayer;
