import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Avatar, IconButton, Typography, Box, Skeleton } from '@mui/material';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import EditProfile from '../components/profile/EditProfile';
import EditNotification from '../components/profile/EditNotification';
import ManageSubscription from '../components/profile/ManageSubscription';
import MeditationTimeSelector from '../components/profile/MeditationTimeSelector';
import Statistics from '../components/profile/Statistics';
import Badges from '../components/profile/Badges';
import Invite from '../components/profile/Invite';
import { useAuth } from '../contexts/AuthContext';
import useDeviceInfo from '../utils/useDeviceInfo';

const ProfilePage = ({ onClose }) => {
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState(false);
  const [isSubscriptionDrawerOpen, setIsSubscriptionDrawerOpen] = useState(false);
  const { currentUser, refreshUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const { paddingTop, paddingBottom } = useDeviceInfo();

  useEffect(() => {
    if (currentUser) {
      setLoading(false);
    }
  }, [currentUser]);

  const handleProfileDrawerOpen = () => {
    setIsProfileDrawerOpen(true);
  };

  const handleProfileDrawerClose = () => {
    setIsProfileDrawerOpen(false);
    refreshUser();
    console.log('Profil mis à jour :', currentUser); // Ajout du log pour voir les informations utilisateur
  };

  const handleNotificationDrawerClose = () => {
    setIsNotificationDrawerOpen(false);
  };

  const handleSubscriptionDrawerOpen = () => {
    setIsSubscriptionDrawerOpen(true);
  };

  const handleSubscriptionDrawerClose = () => {
    setIsSubscriptionDrawerOpen(false);
    refreshUser();
    console.log('Abonnement mis à jour :', currentUser); // Ajout du log pour voir les informations utilisateur après mise à jour
  };

  return (
    <Box sx={{ pt: paddingTop + 2 }}>
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none', verticalAlign: 'middle', px: 1 }}>
        <Toolbar>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
              mr: 1,
            }}
            aria-label="retour"
            onClick={onClose}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            {loading ? (
              <Skeleton variant="text" width={100} animation="wave" />
            ) : (
              <Typography variant="h5" sx={{ color: 'black', lineHeight:'1em' }}>{currentUser?.firstName || 'Chargement...'}</Typography>
            )}
                    <Typography variant="body" sx={{ backgroundColor:'secondary.main', px:1, py:0.5, borderRadius:2, ml:1, color: 'black', lineHeight:'1em' }}>{currentUser?.experiencePoints} XP</Typography>
          </Box>
          
          <IconButton color="inherit" onClick={handleSubscriptionDrawerOpen} sx={{ color: 'black' }}>
            <PaymentRoundedIcon />
          </IconButton>
          <IconButton color="inherit" onClick={handleProfileDrawerOpen} sx={{ color: 'black' }}>
            <SettingsRoundedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <EditProfile isOpen={isProfileDrawerOpen} onClose={handleProfileDrawerClose} onUpdate={refreshUser} />
      <EditNotification isOpen={isNotificationDrawerOpen} onClose={handleNotificationDrawerClose} />
      <ManageSubscription isOpen={isSubscriptionDrawerOpen} onClose={handleSubscriptionDrawerClose} />

      <Box sx={{ pt:1, px: 3, pb: 10 }}>
        <Statistics />
        <MeditationTimeSelector />
        <Invite />
        <Badges />
      </Box>
    </Box>
  );
};

export default ProfilePage;
