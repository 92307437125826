import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button, Switch, FormControlLabel, IconButton, AppBar, Toolbar, Slider, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import AudioPlayerComponent from '../components/AudioPlayerComponent';
import useDeviceInfo from '../utils/useDeviceInfo';

const TimerPage = () => {
  const navigate = useNavigate();
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [showPlayer, setShowPlayer] = useState(false);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const [soundBackground, setSoundBackground] = useState(false); // Désactive l'ambiance par défaut
  const [duration, setDuration] = useState(5); // Durée par défaut
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer pour expliquer les bienfaits du silence

  // Dictionnaire des fichiers audio basés sur la durée et l'ambiance
  const audioFiles = {
    true: {
      5: '/audio/meditation/free_soundtrue_5.mp3',
      10: '/audio/meditation/free_soundtrue_10.mp3',
      20: '/audio/meditation/free_soundtrue_20.mp3',
      30: '/audio/meditation/free_soundtrue_30.mp3',
    },
    false: {
      5: '/audio/meditation/free_soundfalse_5.mp3',
      10: '/audio/meditation/free_soundfalse_10.mp3',
      20: '/audio/meditation/free_soundfalse_20.mp3',
      30: '/audio/meditation/free_soundfalse_30.mp3',
    },
  };

  // Fonction pour verrouiller les valeurs sur 5, 10, 20, ou 30
  const handleSliderChangeCommitted = (event, newValue) => {
    const allowedValues = [5, 10, 20, 30];
    const closestValue = allowedValues.reduce((prev, curr) => {
      return Math.abs(curr - newValue) < Math.abs(prev - newValue) ? curr : prev;
    });
    setDuration(closestValue);
  };

  // Fonction pour sélectionner l'audio en fonction de la durée et de l'ambiance
  const handleSelectAudio = () => {
    const audioSrc = audioFiles[soundBackground][duration];
    setSelectedAudio(audioSrc);
    setShowPlayer(true); // Affiche le lecteur audio
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  // Slider pour la durée (5 à 30 minutes)
  const handleSliderChange = (event, newValue) => {
    setDuration(newValue);
  };

  return (
    <Box sx={{ height: '100vh' }}>
      {/* Toolbar avec bouton de retour */}
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
              mr: 2,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(100vh - 64px)' }}>
        <Container sx={{ textAlign: 'center', px: 3 }}>
          <Typography sx={{ mb: 1 }} variant="h4">
            Méditation libre
          </Typography>

          {/* Slider pour la sélection de la durée */}
          <Box sx={{ border:'1px solid #DDD', width:'fit-content', margin:'auto', px:2, py:1, borderRadius:2 }} >
            <Typography variant="h6">
              Durée : {duration} minutes
            </Typography>
          </Box>
          <Slider
            value={duration}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommitted}
            aria-labelledby="durée-slider"
            min={5}
            max={30}
            step={5}
            valueLabelDisplay="auto"
            marks={[
              { value: 5, label: '5 min' },
              { value: 10, label: '10 min' },
              { value: 20, label: '20 min' },
              { value: 30, label: '30 min' },
            ]}
            sx={{ width: '75%', mb: 4, mt:2 }}
          />

          {/* Sélecteur d'ambiance sonore avec label "Recommandé" */}
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={soundBackground}
                  onChange={() => setSoundBackground(!soundBackground)}
                  color="primary"
                />
              }
              label={soundBackground ? 'Avec ambiance' : 'Sans ambiance'}
            />
            {!soundBackground && (
              <Button onClick={handleOpenDrawer} size="small" startIcon={<InfoOutlinedIcon />} sx={{ color: 'green' }}>
                Recommandé
              </Button>
            )}
          </Box>

          {/* Bouton pour démarrer la méditation */}
          <Button variant="contained" onClick={handleSelectAudio} sx={{ width: '75%' }}>
            Commencer
          </Button>
        </Container>

        {/* Lecteur audio */}
        {showPlayer && selectedAudio && (
          <AudioPlayerComponent
            audioSrc={selectedAudio}
            title={`Méditation de ${duration} minutes`}
            contentType="meditation"
            onClose={() => setShowPlayer(false)}
          />
        )}

        {/* Drawer pour expliquer les bienfaits du silence */}
        <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleCloseDrawer}>
          <Box sx={{ padding: 3 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Les bienfaits du silence
            </Typography>
            <Typography variant="body1">
              Méditer dans le silence, sans fond sonore, aide à développer la pleine conscience. Cela permet de se concentrer uniquement sur sa respiration et ses sensations internes, ce qui améliore la connexion à soi-même et à l'instant présent. Le silence favorise aussi une meilleure gestion du stress et des émotions, et renforce l'efficacité de la méditation pour atteindre un état de calme et de clarté mentale.
            </Typography>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default TimerPage;
