import React, { useState, useEffect } from 'react';
import { Box} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import AudioStreamer from '../../components/room/AudioStreamer';

// Chargez les variables d'environnement
const backendUrl = process.env.REACT_APP_BACKEND_URL;

let socket;

const LiveMeditationSession = () => {
  const { roomCode } = useParams();
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [meditationStartTime, setMeditationStartTime] = useState(0);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(`/room/${roomCode}`);
  };
  
  useEffect(() => {
    socket = io(backendUrl); // Utilisez la variable d'environnement ici
    joinRoom();

    socket.on('updateUserList', (users) => {
      setConnectedUsers(users);
    });

    socket.on('startMeditation', (startTime) => {
      setMeditationStartTime(startTime);
    });

    socket.on('joinMeditationInProgress', (startTime) => {
      setMeditationStartTime(startTime);
    });

    socket.on('updateMeditationState', (state) => {
      switch (state) {
        case 'completed':
          const sessionId = localStorage.getItem('sessionId'); // Récupérer l'ID de session
          if (sessionId) {
            navigate(`/session/${sessionId}`, { state: { fromRoom: true } });
          } else {
            navigate(`/room/${roomCode}`); // Rediriger vers la room si l'ID de session n'est pas trouvé
          }
          break;
        default:
          break;
      }
    });    

    return () => {
      socket.disconnect();
      socket.off();
    };
  }, [roomCode]);

  const joinRoom = () => {
    socket.emit('joinRoom', { room: roomCode, token: localStorage.getItem('token') });
  };

  return (
    <Box>
      <AudioStreamer />
    </Box>
  );
};

export default LiveMeditationSession;
